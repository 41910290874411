import React, { Component, useState } from "react";
import axios from "axios";
import "dropzone/dist/min/dropzone.min.css";
import validator from "validator";
import { api, apiLocal, https } from "../src/apiConfig/apiurl.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Sliders from "react-slick";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { forwardRef, useImperativeHandle } from "react";
import NumberFormat from "react-number-format";
import { Label, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
function round100(num1) {
  return Math.round(num1 / 100) * 100;
}
class App extends Component {
  constructor(props) {
    super(props);
    this.slide1 = React.createRef();
    this.slide2 = React.createRef();
    this.slide3 = React.createRef();
    this.slide4 = React.createRef();
    this.slide5 = React.createRef();
    this.state = {
      staffID:
        this.props.location.search !== ""
          ? parseInt(this.props.location.search.substring(9))
          : parseInt(0),
      color10: false,
      color1: false,
      color2: false,
      color3: false,
      color4: false,
      color5: false,
      color6: false,
      color7: false,
      color8: false,
      color9: false,
      color21: false,
      color22: false,
      color31: false,
      color32: false,
      color33: false,
      filter: "",
      selectedRegistrationDate: new Date(),
      minimumDate: "",
      maximumDate: "",
      api: api,
      https: https,
      collapse: true,
      display: "none",
      vehicleId: "",
      //CustomerId: 0,
      quoteList: [],
      carLeftStyle: -2,
      carLeftStyleFlags: [],
      yearMaster: [
        { id: 1, year: new Date().getFullYear().toString() },
        { id: 2, year: (new Date().getFullYear() - 1).toString() },
        { id: 3, year: (new Date().getFullYear() - 2).toString() },
        { id: 4, year: (new Date().getFullYear() - 3).toString() },
        { id: 5, year: (new Date().getFullYear() - 4).toString() },
        { id: 6, year: (new Date().getFullYear() - 5).toString() },
        { id: 7, year: (new Date().getFullYear() - 6).toString() },
        { id: 8, year: (new Date().getFullYear() - 7).toString() },
        { id: 9, year: (new Date().getFullYear() - 8).toString() },
        { id: 10, year: (new Date().getFullYear() - 9).toString() },
        { id: 11, year: (new Date().getFullYear() - 10).toString() },
        { id: 12, year: (new Date().getFullYear() - 11).toString() },
        { id: 13, year: (new Date().getFullYear() - 12).toString() },
        { id: 14, year: (new Date().getFullYear() - 13).toString() },
        { id: 15, year: (new Date().getFullYear() - 14).toString() },
        { id: 16, year: (new Date().getFullYear() - 15).toString() },
        { id: 17, year: (new Date().getFullYear() - 16).toString() },
        { id: 18, year: (new Date().getFullYear() - 17).toString() },
        { id: 19, year: (new Date().getFullYear() - 18).toString() },
        { id: 20, year: (new Date().getFullYear() - 19).toString() },
      ],
      activeListModelMake: null,
      activeListBodyType: null,
      activeListEngineSize: null,
      activeListMake: null,
      activeListYear: null,
      activeListEmirates: null,
      activeListTrim: null,
      makeMaster: [],
      trimMaster: [],
      modelMake: [],
      bodyTypeMaster: [],
      engineSizeMaster: [],
      emiratesMaster: [
        { id: 1, name: "Abudhabi" },
        { id: 2, name: "Ajman" },
        { id: 3, name: "Dubai" },
        { id: 4, name: "Sharjah" },
        { id: 5, name: "Fujairah" },
        { id: 6, name: "Al Ain" },
        {
          id: 7,
          name: "Ras Al Khaimah",
        },
        {
          id: 8,
          name: "Umm Al Quwain",
        },
      ],
      smShow: false,
      closePopup: "",
      accordionShow: true,
      accordionDisplay: "",
      accordionShow2: true,
      accordionDisplay2: "",
      Sliders: "",
      slideIndex: 0,
      updateCount: 0,
      slideIndex2: 0,
      slideIndex3: 0,
      updateCount2: 0,
      updateCount3: 0,
      currentStep: 1,
      havingMulkiya: false,
      email: "",
      username: "",
      vehicleDetailsIDs: "",
      submitVehicleDetails: false,
      submitotpDetails: false,
      submitPersonalDetails: false,
      submitFetchVehicleDetails: false,

      submitVehicleRegistrationWithoutChassisNumberDetails: false,
      submitVehicleRegistrationWithoutChassisNumber2Details: false,
      submitVehicleRegistrationWithoutChassisNumber3Details: false,
      submitDocumentDetailsMulkiya: false,
      submitDocumentDetailsLicence: false,
      submitDocumentDetailsEmiratesID: false,
      submitfileDetailsMulkiya: false,
      submitfileDetailsLicence: false,
      submitfileDetailsEmiratesID: false,
      password: "",
      files: null,
      loader: false,
      responseMsgHeader: "Can I have your vehicle details",
      responseMsg: "",
      token: "",
      sliderDetails: {
        Sliders: { _value: "" },
        slider2: { _value: "" },
        slider3: { _value: "" },
      },
      makeMasterDetails: {
        makeMaster: { _value: "" },
      },
      vehicleDetails: {
        vehicleId: {
          _value: 0,
          touched: false,
          required: false,
          error: "",
          errorMsg: "Plate Code is required",
          errorMessage: "",
        },
        vehicleDetailsID: {
          _value: 0,
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
          errorMessage: "",
        },
        plateCode: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Plate Code  is required",
          errorMessage: "",
        },
        vehiclecol: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
          errorMessage: "",
        },
        isActive: {
          _value: true,
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
          errorMessage: "",
        },
        chassisNo: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
          errorMessage: "",
        },
        plateNumber: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Plate Number is required",
          errorMessage: "",
        },
      },
      personalDetails: {
        email: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "E-mail is required",
          errorMessage: "",
        },
        yourName: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Your Name is required",
          errorMessage: "",
        },
        mobileNumber: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Mobile Number is required",
          errorMessage: "",
        },
      },
      otpDetails: {
        otp: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
      },
      fetchVehicleDetails: {
        chassisNumber: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        // mulkiya: { _value: "", touched: false, required: true, error: "", errorMsg: "OTP is required",errorMessage:"" },
      },
      vehicleRegistrationWithChassisNumber: {
        chassisNumber: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        dateOfRegistration: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        noClaimYear: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        plateCode: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        plateNumber: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        lOCRegion: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        policyStartDate: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
      },
      vehicleRegistrationWithoutChassisNumberMake: {
        id: { _value: "" },
        make: { _value: "" },
      },

      vehicleRegistrationWithoutChassisNumber: {
        make: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Make is required",
          errorMessage: "",
        },
        model: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Model is required",
          errorMessage: "",
        },
        modelYear: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Model is required",
          errorMessage: "",
        },
        trimValue: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "Trim is required",
          errorMessage: "",
        },
        bodyType: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Car Variant is required",
          errorMessage: "",
        },
        dateOfRegistration: {
          _value: "",
          _values: "",
          touched: true,
          required: true,
          error: "",
          errorMsg: "",
          errorMessage: "",
        },
        dateOfRegistrationIns: {
          _value: "",
          _values: "",
          touched: true,
          required: false,
          error: "",
          errorMsg: "Year of manufacture is required",
          errorMessage: "",
        },
        engineSize: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Engine Size is required",
          errorMessage: "",
        },
        noClaimYear: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        gcc: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        plateCode: {
          _value: "",
          touched: true,
          required: false,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        plateNumber: {
          _value: "",
          touched: true,
          required: false,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        lOCRegion: {
          _value: "",
          touched: true,
          required: false,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        policyStartDate: {
          _value: "",
          touched: true,
          required: false,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        userName: {
          _value: "",
          touched: true,
          required: false,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        vehicleValue: {
          _value: 0,
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        vehicleMinValue: {
          _value: 20000,
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        vehicleMaxValue: {
          _value: 1000000,
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },

        vehicleTxtPrice: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        vehiclePrice: {
          _value: 0,
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        dLIssuedDate: {
          _value: "",
          _values: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "",
          errorMessage: "",
        },
        dLIssuedDateIns: {
          _value: "",
          _values: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        dLEmirate: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
      },
      vehicleRegistrationWithoutChassisNumber2: {
        vehicleValue: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Vehicle Value is required",
          errorMessage: "",
        },
      },
      vehicleRegistrationWithoutChassisNumber3: {
        dLIssuedDates: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Driving License Issued is required",
          errorMessage: "",
        },
        dLEmirate: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Emirate issued is required",
          errorMessage: "",
        },
      },
      getMotorQuotes: {
        vehicleDetailsID: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
      },
      getQuoteListingLink: {
        vehicleDetailsID: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        insName: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        planName: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
      },
      getQuoteDetails: {
        quoteNumber: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
      },
      updateCover: {
        refId: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        quoteNo: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        coverageid: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
      },
      getPaymentLink: {
        quoteNumber: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
      },

      updatePrepolicyFieldsandGeneratePolicyNo: {
        quoteNo: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        insName: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        insData: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        previousPolicyEndDate: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        policyEndDate: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        policyStartDate: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        address: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        pOBoxNo: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        emiratesoftheInsured: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        uAEDriverExperienceinYears: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        isGC: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        engineSize: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        vehicleColor: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        noOfDoors: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        noOfSeats: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        isMortgage: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        mortgageBank: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        emirateExpiryDate: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        dLExpiryDate: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
      },
      getPrePolicyFields: {
        quoteNumber: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
      },
      getPolicyCertificate: {
        policyNo: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
      },
      saveFeedBack: {
        userName: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        quoteNO: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        Rating: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
      },
      documentDetailsMulkiya: {
        documentMulkiyaF: {
          _value1: "",
          touched1: false,
          required: true,
          error: "",
          errorMsg: "Document Mulkiya Front is required",
          errorMessage: "",
        },
        documentMulkiyaB: {
          _value2: "",
          touched2: false,
          required: true,
          error: "",
          errorMsg: "Document Mulkiya Back is required",
          errorMessage: "",
        },
      },
      documentDetailsLicence: {
        documentLicenceF: {
          _value1: "",
          touched1: false,
          required: true,
          error: "",
          errorMsg: "Document Licence Front is required",
          errorMessage: "",
        },
        documentLicenceB: {
          _value2: "",
          touched2: false,
          required: true,
          error: "",
          errorMsg: "Document Licence Back is required",
          errorMessage: "",
        },
      },
      documentDetailsEmiratesID: {
        documentEmiratesIDF: {
          _value1: "",
          touched1: false,
          required: true,
          error: "",
          errorMsg: "Document EmiratesID Front is required",
          errorMessage: "",
        },
        documentEmiratesIDB: {
          _value2: "",
          touched2: false,
          required: true,
          error: "",
          errorMsg: "Document EmiratesID Back is required",
          errorMessage: "",
        },
      },
      fileDetailsMulkiya: {
        documentDetailsID: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
        plateCategory: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        plateNo: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        regDate: {
          _value: new Date(),
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
        expDate: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
        insExp: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
        chassisNumber: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        engineNumber: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
        trim: {
          _value: "1",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
        cylinder: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
        engineSize: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
      },

      fileDetailsLicence: {
        licenceNo: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
        placeofIssue: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
        issueDate: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
        expiryDate: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
        TCNo: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
      },

      fileDetailsEmiratesID: {
        nationality: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
        emiratesID: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
        expiryDate: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
        dateofBirth: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
        gender: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
      },
      time: {},
      seconds: 0,
    };
    this.timer = 60;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this.previousButton = this.previousButton.bind(this);
    this.nextButton = this.nextButton.bind(this);
    this.handleVehicleDetailChange = this.handleVehicleDetailChange.bind(this);
    this.handleVehicleDetailsValidations =
      this.handleVehicleDetailsValidations.bind(this);
    this.handlePersonalDetailsValidations =
      this.handlePersonalDetailsValidations.bind(this);
    this.handleotpDetailsValidations =
      this.handleotpDetailsValidations.bind(this);
    this.handleFetchVehicleDetailsValidations =
      this.handleFetchVehicleDetailsValidations.bind(this);
    this.handleVehicleRegistrationWithoutChassisNumberDetailsValidations =
      this.handleVehicleRegistrationWithoutChassisNumberDetailsValidations.bind(
        this
      );
    this.handleVehicleRegistrationWithoutChassisNumber2DetailsValidations =
      this.handleVehicleRegistrationWithoutChassisNumber2DetailsValidations.bind(
        this
      );
    this.handleVehicleRegistrationWithoutChassisNumber3DetailsValidations =
      this.handleVehicleRegistrationWithoutChassisNumber3DetailsValidations.bind(
        this
      );
    this.handlefileDetailsValidationsMulkiya =
      this.handlefileDetailsValidationsMulkiya.bind(this);
    this.handlefileDetailsValidationsLicence =
      this.handlefileDetailsValidationsLicence.bind(this);
    this.handlefileDetailsValidationsEmiratesID =
      this.handlefileDetailsValidationsEmiratesID.bind(this);
    this.handleDocumentDetailsValidationsMulkiya =
      this.handleDocumentDetailsValidationsMulkiya.bind(this);
    this.handleDocumentDetailsValidationsLicence =
      this.handleDocumentDetailsValidationsLicence.bind(this);
    this.handleDocumentDetailsValidationsEmiratesID =
      this.handleDocumentDetailsValidationsEmiratesID.bind(this);
    this.handlePersonalDetailChange =
      this.handlePersonalDetailChange.bind(this);
    this.handleFetchVehicleDetailChange =
      this.handleFetchVehicleDetailChange.bind(this);
    this.handleVehicleRegistrationWithoutChassisNumberDetailChange =
      this.handleVehicleRegistrationWithoutChassisNumberDetailChange.bind(this);
    this.handleVehicleRegistrationWithoutChassisNumber2DetailChange =
      this.handleVehicleRegistrationWithoutChassisNumber2DetailChange.bind(
        this
      );
    this.handleVehicleRegistrationWithoutChassisNumber3DetailChange =
      this.handleVehicleRegistrationWithoutChassisNumber3DetailChange.bind(
        this
      );
    this.handleotpDetailChange = this.handleotpDetailChange.bind(this);
    this.handlefileDetailMulkiyaRegDateChange =
      this.handlefileDetailMulkiyaRegDateChange.bind(this);
    this.handlefileDetailMulkiyaExpDateChange =
      this.handlefileDetailMulkiyaExpDateChange.bind(this);
    this.handlefileDetailMulkiyaInsDateChange =
      this.handlefileDetailMulkiyaInsDateChange.bind(this);
    this.handlefileDetailMulkiyaChange =
      this.handlefileDetailMulkiyaChange.bind(this);

    this.increcarLeftStyle = this.increcarLeftStyle.bind(this);
    this.handlefileDetailLicenceChange =
      this.handlefileDetailLicenceChange.bind(this);
    this.handlefileDetailEmiratesIDChange =
      this.handlefileDetailEmiratesIDChange.bind(this);
    this.resetDocumentsMulkiya = this.resetDocumentsMulkiya.bind(this);
    this.resetDocumentsLicence = this.resetDocumentsLicence.bind(this);
    this.resetDocumentsEmiratesID = this.resetDocumentsEmiratesID.bind(this);
    this.handledocumentMulkiyaFChange =
      this.handledocumentMulkiyaFChange.bind(this);
    this.handledocumentMulkiyaBChange =
      this.handledocumentMulkiyaBChange.bind(this);
    this.handledocumentLicenceFChange =
      this.handledocumentLicenceFChange.bind(this);
    this.handledocumentLicenceBChange =
      this.handledocumentLicenceBChange.bind(this);
    this.handledocumentEmiratesIDFChange =
      this.handledocumentEmiratesIDFChange.bind(this);
    this.handledocumentEmiratesIDBChange =
      this.handledocumentEmiratesIDBChange.bind(this);
    this.setSmShow = this.setSmShow.bind(this);
    this.onVehicleTxtPriceChange = this.onVehicleTxtPriceChange.bind(this);
    this.onChangeMulkiyaValue = this.onChangeMulkiyaValue.bind(this);
    this.handleClickYear = this.handleClickYear.bind(this);
    this.handleClickBodyType = this.handleClickBodyType.bind(this);
    this.handleClickEngineSize = this.handleClickEngineSize.bind(this);
    this.handleClickEmirates = this.handleClickEmirates.bind(this);
    this.handleClickMake = this.handleClickMake.bind(this);
    this.handleClickTrim = this.handleClickTrim.bind(this);
    this.setState = this.setState.bind(this);
    this.getMakeApi = this.getMakeApi.bind(this);
    this.getEngineSizeApi = this.getEngineSizeApi.bind(this);
    this.getModelMakeApi = this.getModelMakeApi.bind(this);
    this.getBodyTypeApi = this.getBodyTypeApi.bind(this);
    this.getTrimApi = this.getTrimApi.bind(this);
    this.getMulkiyaDetails = this.getMulkiyaDetails.bind(this);
    // afterChange= afterChange.bind(this);
    this.handleRegistrationDate = this.handleRegistrationDate.bind(this);
    this.handleDrivingLicenseIssuedDate =
      this.handleDrivingLicenseIssuedDate.bind(this);
    this.onClickStep1 = this.onClickStep1.bind(this);
    this.onClickStep2 = this.onClickStep2.bind(this);
    this.onClickStep3 = this.onClickStep3.bind(this);
    this.onClickStep4 = this.onClickStep4.bind(this);
    this.onClickStep5 = this.onClickStep5.bind(this);
    this.collapse = this.collapse.bind(this);
    this.accordionShow = this.accordionShow.bind(this);
    this.accordionShow2 = this.accordionShow2.bind(this);
  }

  componentDidMount() {
    console.log("jane", this.state.staffID); //http://localhost:3001/staff?staffId=9
    this.setState({ loader: true });
    var hrs = 48;
    var now = new Date().getTime();
    var setupTime = localStorage.getItem("setupTime");
    if (setupTime == null) {
      localStorage.setItem("setupTime", now);
    } else {
      if (now - setupTime > hrs * 60 * 60 * 1000) {
        localStorage.clear();
        localStorage.setItem("setupTime", now);
      }
    }
    console.log("setuptime", now, setupTime, hrs * 60 * 60 * 1000);
    this.accordionShow2();
    this.startTimer();
    this.getMakeApi();
    // this.getBodyTypeApi();
    // this.getEngineSizeApi();
    axios
      .post(this.state.api + `Vehicle/GenerateToken`)
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data.token));
        }
        ////console.log('response', response)
        this.setState({
          token: response.data.token,
        });
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          //  this.setState({ loader: false,smShow:true });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          //  this.setState({ loader: false,smShow:true });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          // this.setState({ loader: false,smShow:true });
        }
      });
    if (
      localStorage.getItem("VehicleRegistrationWithoutChassisNumber") !== null
    ) {
      this.collapse();
      var retrievedObject = localStorage.getItem(
        "VehicleRegistrationWithoutChassisNumber"
      );
      var retrievedObjectVehicleDetails =
        localStorage.getItem("VehicleDetails");
      var retrievedObjectPersonalDetails =
        localStorage.getItem("PersonalDetails");
      this.setState((prevState) => {
        let vehicleRegistrationWithoutChassisNumber = Object.assign(
          {},
          prevState.vehicleRegistrationWithoutChassisNumberStorage
        );
        let vehicleDetails = Object.assign({}, prevState.vehicleDetails);
        let personalDetails = Object.assign({}, prevState.personalDetails);
        let activeListYear = Object.assign({}, prevState.activeListYear);
        let activeListMake = Object.assign({}, prevState.activeListMake);
        let activeListBodyType = Object.assign(
          {},
          prevState.activeListBodyType
        );
        let activeListModelMake = Object.assign(
          {},
          prevState.activeListModelMake
        );
        let activeListTrim = Object.assign({}, prevState.activeListTrim);
        let activeListEngineSize = Object.assign(
          {},
          prevState.activeListEngineSize
        );
        let activeListEmirates = Object.assign(
          {},
          prevState.activeListEmirates
        );
        vehicleRegistrationWithoutChassisNumber = JSON.parse(retrievedObject);
        if (localStorage.getItem("VehicleDetails") !== null)
          vehicleDetails = JSON.parse(retrievedObjectVehicleDetails);
        if (localStorage.getItem("PersonalDetails") !== null)
          personalDetails = JSON.parse(retrievedObjectPersonalDetails);
        activeListYear =
          vehicleRegistrationWithoutChassisNumber.modelYear._value;
        activeListMake = vehicleRegistrationWithoutChassisNumber.make._value;
        activeListBodyType =
          vehicleRegistrationWithoutChassisNumber.bodyType._value;
        activeListModelMake =
          vehicleRegistrationWithoutChassisNumber.model._value;
        activeListTrim =
          vehicleRegistrationWithoutChassisNumber.trimValue._value;
        activeListEngineSize =
          vehicleRegistrationWithoutChassisNumber.engineSize._value;
        activeListEmirates =
          vehicleRegistrationWithoutChassisNumber.dLEmirate._value;
        this.getModelMakeApi(
          vehicleRegistrationWithoutChassisNumber.make._value
        );
        this.getTrimApi(
          vehicleRegistrationWithoutChassisNumber.make._value,
          vehicleRegistrationWithoutChassisNumber.model._value,
          vehicleRegistrationWithoutChassisNumber.modelYear._value
        );

        return {
          vehicleRegistrationWithoutChassisNumber:
            vehicleRegistrationWithoutChassisNumber,
          vehicleDetails: vehicleDetails,
          personalDetails: personalDetails,
          activeListYear: activeListYear,
          activeListMake: activeListMake,
          activeListModelMake: activeListModelMake,
          activeListBodyType: activeListBodyType,
          activeListTrim: activeListTrim,
          activeListEngineSize: activeListEngineSize,
          activeListEmirates: activeListEmirates,
          carLeftStyle: 9,
        };
      });
    }
  }
  componentDidUpdate() {}

  generateArrayOfYears() {
    var max = new Date().getFullYear();
    var min = max - 9;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }
  startTimer() {
    if (this.timer === 60 && this.state.seconds >= 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds + 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds === 60) {
      clearInterval(this.timer);
    }
  }
  increcarLeftStyle() {
    this.setState((prevState) => ({
      carLeftStyle: prevState.carLeftStyle + 9,
    }));
  }
  accordionShow() {
    this.setState((prevState) => {
      let collapse;
      let display = Object.assign({}, prevState.accordionShow);
      collapse = !prevState.accordionShow ? "collapse show" : "collapse";
      display = !prevState.accordionShow;
      //console.log(display,collapse)
      // this.state.accordionDisplay = collapse;
      return { accordionDisplay: collapse, accordionShow: display };
    });
  }
  accordionShow2() {
    this.setState((prevState) => {
      let collapse;
      let display = Object.assign({}, prevState.accordionShow2);
      collapse = !prevState.accordionShow2 ? "collapse show" : "collapse";
      display = !prevState.accordionShow2;
      //console.log(display,collapse)
      // this.state.accordionDisplay = collapse;
      return { accordionDisplay2: collapse, accordionShow2: display };
    });
  }
  collapse() {
    this.setState((prevState) => {
      let collapse = Object.assign({}, prevState.collapse);
      let display = Object.assign({}, this.state.display);
      display = "block";
      collapse = false;
      return { collapse, display: display };
    });
  }
  onClickStep1(a, b) {
    // console.log
    this.setState(
      {
        carLeftStyle: a * 9,
        currentStep: 1,
      },
      () => {
        this.slide1.current.gotoSlide1(a, b);
      }
    );
  }

  onClickStep2(a, b) {
    this.setState(
      {
        currentStep: 2,
      },
      () => {
        this.slide2.current.gotoSlide2(a, b);
      }
    );

    this.setState({ loader: false });
  }
  onClickStep3(a, b) {
    this.setState(
      {
        currentStep: 3,
      },
      () => {
        this.slide3.current.gotoSlide3(a, b);
      }
    );
  }
  onClickStep4(a, b) {
    this.setState(
      {
        currentStep: 4,
      },
      () => {
        this.slide4.current.gotoSlide4(a, b);
      }
    );
  }
  onClickStep5(a, b) {
    this.setState(
      {
        currentStep: 5,
      },
      () => {
        this.slide5.current.gotoSlide5(a, b);
      }
    );
  }
  handleClickYear = (id, year) => {
    console.log(year);
    if (this.state.carLeftStyleFlags.indexOf("year") === -1) {
      this.state.carLeftStyleFlags.push("year");

      this.increcarLeftStyle();
    }
    const defaultValue = {
      year: parseInt(year),
      month: 1,
      day: 1,
    };
    this.setState((prevState) => {
      let vehicleRegistrationWithoutChassisYear = Object.assign(
        {},
        prevState.vehicleRegistrationWithoutChassisNumber
      );
      let color1 = Object.assign({}, prevState.color1);
      vehicleRegistrationWithoutChassisYear["modelYear"]._value = year;
      vehicleRegistrationWithoutChassisYear["dateOfRegistration"]._value =
        defaultValue;
      vehicleRegistrationWithoutChassisYear["modelYear"].touched = true;
      color1 = true;
      return {
        vehicleRegistrationWithoutChassisNumber:
          vehicleRegistrationWithoutChassisYear,
        color1: color1,
      };
    });
    this.state.sliderDetails.Sliders._value.slickGoTo(
      this.state.slideIndex + 1
    );
    this.collapse();
    this.setState((state) => ({
      slideIndex: state.slideIndex + 1,
      activeListYear: year,
    }));
  };
  handleClickMake = (id, make) => {
    this.getModelMakeApi(make);
    if (this.state.carLeftStyleFlags.indexOf("make") === -1) {
      this.state.carLeftStyleFlags.push("make");

      this.increcarLeftStyle();
    }
    let vehicleRegistrationWithoutChassisMake = Object.assign(
      {},
      this.state.vehicleRegistrationWithoutChassisNumber
    );
    let color2 = Object.assign({}, this.state.color2);
    let color3 = Object.assign({}, this.state.color3);
    let color4 = Object.assign({}, this.state.color4);
    vehicleRegistrationWithoutChassisMake["make"]._value = make;
    vehicleRegistrationWithoutChassisMake["make"].touched = true;
    color3 = false;
    color4 = false;
    color2 = true;
    this.state.sliderDetails.Sliders._value.slickGoTo(
      this.state.slideIndex + 1
    );
    this.setState((state) => ({
      slideIndex: state.slideIndex + 1,
      vehicleRegistrationWithoutChassisNumber:
        vehicleRegistrationWithoutChassisMake,
      activeListMake: make,
      color2: color2,
      color3: color3,
      color4: color4,
    }));
  };
  handleClickModelMake = (id, model) => {
    if (this.state.carLeftStyleFlags.indexOf("model") === -1) {
      this.state.carLeftStyleFlags.push("model");

      this.increcarLeftStyle();
    }
    let vehicleRegistrationWithoutChassisTrim = Object.assign(
      {},
      this.state.vehicleRegistrationWithoutChassisNumber
    );
    vehicleRegistrationWithoutChassisTrim["model"]._value = model;
    vehicleRegistrationWithoutChassisTrim["model"].touched = true;
    let color3 = Object.assign({}, this.state.color3);
    let color4 = Object.assign({}, this.state.color4);
    color3 = true;
    color4 = false;
    this.state.sliderDetails.Sliders._value.slickGoTo(
      this.state.slideIndex + 1
    );
    this.setState((state) => ({
      slideIndex: state.slideIndex + 1,
      color3: color3,
      color4: color4,
      vehicleRegistrationWithoutChassisNumber:
        vehicleRegistrationWithoutChassisTrim,
      activeListModelMake: model,
    }));
    this.getTrimApi(
      this.state.vehicleRegistrationWithoutChassisNumber.make._value,
      model,
      this.state.vehicleRegistrationWithoutChassisNumber.modelYear._value
    );
  };
  handleClickTrim = (id, trim) => {
    if (this.state.carLeftStyleFlags.indexOf("trim") === -1) {
      this.state.carLeftStyleFlags.push("trim");

      this.increcarLeftStyle();
    }
    let vehicleRegistrationWithoutChassisEngineSize = Object.assign(
      {},
      this.state.vehicleRegistrationWithoutChassisNumber
    );
    vehicleRegistrationWithoutChassisEngineSize["trimValue"]._value = trim;
    vehicleRegistrationWithoutChassisEngineSize["trimValue"].touched = true;
    let color4 = Object.assign({}, this.state.color4);
    color4 = true;
    this.state.sliderDetails.Sliders._value.slickGoTo(
      this.state.slideIndex + 1
    );
    this.setState((state) => ({
      slideIndex: state.slideIndex + 1,
      color4: color4,
      vehicleRegistrationWithoutChassisNumber:
        vehicleRegistrationWithoutChassisEngineSize,
      activeListTrim: trim,
    }));
    this.getBodyTypeApi();
  };
  handleClickBodyType = (id, bodyType) => {
    if (this.state.carLeftStyleFlags.indexOf("body") === -1) {
      this.state.carLeftStyleFlags.push("body");

      this.increcarLeftStyle();
    }
    let vehicleRegistrationWithoutChassisBodyType = Object.assign(
      {},
      this.state.vehicleRegistrationWithoutChassisNumber
    );
    vehicleRegistrationWithoutChassisBodyType["bodyType"]._value = bodyType;
    vehicleRegistrationWithoutChassisBodyType["bodyType"].touched = true;
    let color5 = Object.assign({}, this.state.color5);
    color5 = true;

    this.setState((prevState) => {
      let activeListBodyType = Object.assign({}, prevState.activeListBodyType);
      activeListBodyType = bodyType;
      return { activeListBodyType: activeListBodyType, color5: color5 };
    });
    this.setState((state) => ({
      slideIndex: state.slideIndex + 1,
      vehicleRegistrationWithoutChassisNumber:
        vehicleRegistrationWithoutChassisBodyType,
    }));
    this.state.sliderDetails.Sliders._value.slickGoTo(
      this.state.slideIndex + 1
    );
    this.getEngineSizeApi();
  };
  handleClickEngineSize = (id, engineSize) => {
    if (this.state.carLeftStyleFlags.indexOf("engine") === -1) {
      this.state.carLeftStyleFlags.push("engine");

      this.increcarLeftStyle();
    }
    let vehicleRegistrationWithoutChassisEngineSize = Object.assign(
      {},
      this.state.vehicleRegistrationWithoutChassisNumber
    );
    vehicleRegistrationWithoutChassisEngineSize["engineSize"]._value =
      engineSize;
    vehicleRegistrationWithoutChassisEngineSize["engineSize"].touched = true;
    let color6 = Object.assign({}, this.state.color6);
    color6 = true;
    this.state.sliderDetails.Sliders._value.slickGoTo(
      this.state.slideIndex + 1
    );
    this.setState((state) => ({
      slideIndex: state.slideIndex + 1,
      color6: color6,
      vehicleRegistrationWithoutChassisNumber:
        vehicleRegistrationWithoutChassisEngineSize,
      activeListEngineSize: engineSize,
    }));
  };
  handleRegistrationDate(day) {
    if (this.state.carLeftStyleFlags.indexOf("reg") === -1) {
      this.state.carLeftStyleFlags.push("reg");

      this.increcarLeftStyle();
    }
    let date = {
      month: day.month,
      year: day.year,
    };
    console.log("day", day);
    let value = day.day + "/" + date["month"] + "/" + date["year"];
    value = value.split("/");
    value =
      (value[0] < 10 ? "0" + value[0] : value[0]) +
      "/" +
      (value[1] < 10 ? "0" + value[1] : value[1]) +
      "/" +
      value[2];

    this.setState((prevState) => {
      let vehicleRegistrationWithoutChassisRegistrationDate = Object.assign(
        {},
        prevState.vehicleRegistrationWithoutChassisNumber
      );
      // vehicleRegistrationWithoutChassisRegistrationDate['dateOfRegistration']._value = '';
      let color7 = Object.assign({}, prevState.color4);
      vehicleRegistrationWithoutChassisRegistrationDate[
        "dateOfRegistration"
      ]._value = day;
      vehicleRegistrationWithoutChassisRegistrationDate[
        "dateOfRegistration"
      ]._values = value;
      vehicleRegistrationWithoutChassisRegistrationDate[
        "dateOfRegistration"
      ].touched = true;
      color7 = true;
      return {
        vehicleRegistrationWithoutChassisNumber:
          vehicleRegistrationWithoutChassisRegistrationDate,
        color7: color7,
      };
    });
    this.state.sliderDetails.Sliders._value.slickGoTo(
      this.state.slideIndex + 1
    );
    this.setState((state) => ({ slideIndex: state.slideIndex + 1 }));
  }
  onVehicleTxtPriceChange(event) {
    let { value } = event.target;
    console.log(value);

    let vehicleRegistrationWithoutChassisNumberVehicleValue = Object.assign(
      {},
      this.state.vehicleRegistrationWithoutChassisNumber
    );

    vehicleRegistrationWithoutChassisNumberVehicleValue["vehiclePrice"]._value =
      parseInt(value ? value : "0");
    vehicleRegistrationWithoutChassisNumberVehicleValue[
      "vehicleTxtPrice"
    ]._value = value;
    let currentYear = new Date();
    let years =
      currentYear.getFullYear() -
      vehicleRegistrationWithoutChassisNumberVehicleValue["modelYear"]._value;
    let vehicleActualPrice =
      vehicleRegistrationWithoutChassisNumberVehicleValue["vehiclePrice"]
        ._value;

    for (let i = 0; i < years; i++) {
      vehicleActualPrice = round100(
        vehicleActualPrice - (vehicleActualPrice * 15) / 100
      );
    }
    vehicleActualPrice = Number(vehicleActualPrice).toFixed(0);
    vehicleRegistrationWithoutChassisNumberVehicleValue["vehicleValue"]._value =
      vehicleActualPrice;
    let vehicleMinValue = round100(
      vehicleActualPrice - (vehicleActualPrice * 5) / 100
    );
    vehicleMinValue = Number(vehicleMinValue).toFixed(0);
    vehicleRegistrationWithoutChassisNumberVehicleValue[
      "vehicleMinValue"
    ]._value = Number(vehicleMinValue);
    let maxPrice = round100((vehicleActualPrice * 5) / 100);
    let vehicleMaxValue = Number(vehicleActualPrice) + Number(maxPrice);
    vehicleMaxValue = Number(vehicleMaxValue).toFixed(0);
    vehicleRegistrationWithoutChassisNumberVehicleValue[
      "vehicleMaxValue"
    ]._value = Number(vehicleMaxValue);

    this.setState((state) => ({
      vehicleRegistrationWithoutChassisNumber:
        vehicleRegistrationWithoutChassisNumberVehicleValue,
    }));
    ////console.log(this.state.vehicleRegistrationWithoutChassisNumber)
    return vehicleRegistrationWithoutChassisNumberVehicleValue;
  }
  handleDrivingLicenseIssuedDate(day) {
    if (this.state.carLeftStyleFlags.indexOf("license") === -1) {
      this.state.carLeftStyleFlags.push("license");

      this.increcarLeftStyle();
    }
    let date = {
      month: day.month,
      year: day.year,
    };
    let value = day.day + "/" + date["month"] + "/" + date["year"];
    value = value.split("/");
    value =
      (value[0] < 10 ? "0" + value[0] : value[0]) +
      "/" +
      (value[1] < 10 ? "0" + value[1] : value[1]) +
      "/" +
      value[2];
    let vehicleRegistrationWithoutChassisDrivingLicenseIssuedDate =
      Object.assign({}, this.state.vehicleRegistrationWithoutChassisNumber);
    vehicleRegistrationWithoutChassisDrivingLicenseIssuedDate[
      "dLIssuedDate"
    ]._value = day;
    vehicleRegistrationWithoutChassisDrivingLicenseIssuedDate[
      "dLIssuedDate"
    ]._values = value;
    vehicleRegistrationWithoutChassisDrivingLicenseIssuedDate[
      "dLIssuedDate"
    ].touched = true;
    let color8 = Object.assign({}, this.state.color8);
    color8 = true;
    this.state.sliderDetails.Sliders._value.slickGoTo(
      this.state.slideIndex + 1
    );
    this.setState((state) => ({
      slideIndex: state.slideIndex + 1,
      color8: color8,
      vehicleRegistrationWithoutChassisNumber:
        vehicleRegistrationWithoutChassisDrivingLicenseIssuedDate,
    }));

    return vehicleRegistrationWithoutChassisDrivingLicenseIssuedDate;
  }
  handleClickEmirates = (id, name) => {
    if (this.state.carLeftStyleFlags.indexOf("emirates") === -1) {
      this.state.carLeftStyleFlags.push("emirates");

      this.increcarLeftStyle();
    }
    let vehicleRegistrationWithoutChassisEmirates = Object.assign(
      {},
      this.state.vehicleRegistrationWithoutChassisNumber
    );
    vehicleRegistrationWithoutChassisEmirates["dLEmirate"]._value = name;
    vehicleRegistrationWithoutChassisEmirates["dLEmirate"].touched = true;
    let color9 = Object.assign({}, this.state.color9);
    color9 = true;
    this.state.sliderDetails.Sliders._value.slickGoTo(
      this.state.slideIndex + 1
    );
    this.setState((state) => ({
      slideIndex: state.slideIndex + 1,
      vehicleRegistrationWithoutChassisNumber:
        vehicleRegistrationWithoutChassisEmirates,
      activeListEmirates: name,
      color9: color9,
    }));
  };
  horizontalScroll = () => {
    document.body.style.overflow = "auto";
  };
  setSmShow() {
    this.setState((prevState) => {
      let smShow = Object.assign({}, prevState.smShow);
      smShow = !prevState.smShow;
      return { smShow };
    });
  }
  onChangeMulkiyaValue(event) {
    ////console.log(event.target.value)

    this.setState((prevState) => {
      let havingMulkiya = Object.assign({}, prevState.havingMulkiya);
      havingMulkiya = !prevState.havingMulkiya;
      return { havingMulkiya };
    });
  }
  getBodyTypeApi() {
    let body = [],
      bodyType = [];
    axios
      .get(
        this.state.api +
          `Master/GetBodyTypeList?Make=${this.state.vehicleRegistrationWithoutChassisNumber.make._value}&Model=${this.state.vehicleRegistrationWithoutChassisNumber.model._value}&ModelYear=${this.state.vehicleRegistrationWithoutChassisNumber.modelYear._value}&Trim=${this.state.vehicleRegistrationWithoutChassisNumber.trimValue._value}`
      )
      .then((response) => {
        // this.state.bodyTypeMaster = response.data;
        if (response.data.length) {
          for (let Body of response.data) {
            for (let i = 0; i < Body[0].length; i++) {
              body = {
                id: i,
                bodyType: Body,
              };
              bodyType.push(body);
            }
          }

          this.setState((prevState) => {
            let bodyTypeMaster = Object.assign({}, prevState.bodyTypeMaster);
            bodyTypeMaster = bodyType;
            return { bodyTypeMaster: bodyTypeMaster };
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          //  this.setState({ loader: false,smShow:true });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          // this.setState({ loader: false,smShow:true });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          // this.setState({ loader: false,smShow:true });
        }
      });
  }
  getTrimApi(make, model, modelYear) {
    let trim = [],
      trimValue = [];
    axios
      .get(
        this.state.api +
          `Master/GetTrimList?Make=${make}&Model=${model}&ModelYear=${modelYear}`
      )
      .then((response) => {
        if (response.data.length) {
          for (let Trim of response.data) {
            for (let i = 0; i < Trim[0].length; i++) {
              trim = {
                id: i,
                trim: Trim,
              };
              trimValue.push(trim);
              if (Trim === "NA") this.handleClickTrim(1, "NA");
            }
          }
        }

        this.setState((prevState) => {
          let trimMaster = Object.assign({}, prevState.trimMaster);
          trimMaster = trimValue;
          console.log(trimMaster);
          return { trimMaster: trimMaster };
        });
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          //  this.setState({ loader: false,smShow:true });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          // this.setState({ loader: false,smShow:true });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          // this.setState({ loader: false,smShow:true });
        }
      });
  }
  getMakeApi() {
    axios
      .get(this.state.api + `Master/GetMake`)
      .then((response) => {
        // ////console.log('click')
        this.state.makeMaster = response.data;
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          //  this.setState({ loader: false,smShow:true });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          //     this.setState({ loader: false,smShow:true });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          //   this.setState({ loader: false,smShow:true });
        }
      });
  }
  getEngineSizeApi() {
    let engine = [],
      engineSize = [];
    axios
      .get(
        this.state.api +
          `Master/GetEngineSizeList?Make=${this.state.vehicleRegistrationWithoutChassisNumber.make._value}&Model=${this.state.vehicleRegistrationWithoutChassisNumber.model._value}&ModelYear=${this.state.vehicleRegistrationWithoutChassisNumber.modelYear._value}&Trim=${this.state.vehicleRegistrationWithoutChassisNumber.trimValue._value}&BodyType=${this.state.vehicleRegistrationWithoutChassisNumber.bodyType._value}`
      )
      .then((response) => {
        if (response.data.length) {
          for (let Engine of response.data) {
            for (let i = 0; i < Engine[0].length; i++) {
              engine = {
                id: i,
                engineSize: Engine,
              };
              engineSize.push(engine);
            }
          }

          this.setState((prevState) => {
            let engineSizeMaster = Object.assign(
              {},
              prevState.engineSizeMaster
            );
            engineSizeMaster = engineSize;
            return { engineSizeMaster: engineSizeMaster };
          });
        } else {
          axios
            .get(this.state.api + `Master/GetEngineSize`)
            .then((response) => {
              this.setState({ engineSizeMaster: response.data });
              // this.state.engineSizeMaster = response.data;
              // return response.data;
            });
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          //   this.setState({ loader: false,smShow:true });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          //   this.setState({ loader: false,smShow:true });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          //   this.setState({ loader: false,smShow:true });
        }
      });
  }
  getModelMakeApi(make) {
    axios
      .get(this.state.api + `Master/GetModelMake?Make=${make}`)
      .then((response) => {
        this.state.modelMake = response.data;
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          //  this.setState({ loader: false,smShow:true });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          //  this.setState({ loader: false,smShow:true });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          // this.setState({ loader: false,smShow:true });
        }
      });
  }

  handleVehicleDetailChange(event) {
    ////console.log(event)
    let { name, value, validationMessage } = event.target;
    this.setState((prevState) => {
      let vehicleDetails = Object.assign({}, this.state.vehicleDetails);
      vehicleDetails[name]._value = value;
      vehicleDetails[name].touched = true;
      vehicleDetails[name].errorMessage = validationMessage;
      if (
        event.target.name === "plateCode" &&
        vehicleDetails["plateCode"].errorMessage !== ""
      ) {
        //  debugger;
        vehicleDetails[name].errorMessage =
          "Special Characters are not allowed";
      }
      if (
        event.target.name === "plateNumber" &&
        vehicleDetails["plateNumber"].errorMessage !== ""
      )
        vehicleDetails[name].errorMessage =
          "Characters and Special Characters are not allowed";

      return vehicleDetails;
    });
  }

  handlePersonalDetailChange(event) {
    let { name, value, validationMessage } = event.target;
    this.setState((prevState) => {
      // ////console.log('test')
      let personalDetails = Object.assign({}, this.state.personalDetails);
      personalDetails[name]._value = value;
      personalDetails[name].touched = true;
      personalDetails[name].errorMessage = validationMessage;
      ////console.log('validation', personalDetails['mobileNumber'].errorMessage)

      if (
        event.target.name === "mobileNumber" &&
        personalDetails["mobileNumber"].errorMessage !== ""
      )
        personalDetails[name].errorMessage =
          "10 digit Mobile Number is allowed";

      if (
        event.target.name === "yourName" &&
        personalDetails["yourName"].errorMessage !== ""
      )
        personalDetails[name].errorMessage =
          "Minimum 3 Characters and Only Special Character Space is allowed";

      if (
        !validator.isEmail(value) &&
        event.target.name === "email" &&
        personalDetails["email"]._value !== ""
      )
        personalDetails[name].errorMessage = "Invalid Email";
      return personalDetails;
    });
  }
  handleotpDetailChange(event) {
    let { name, value, validationMessage } = event.target;
    this.setState((prevState) => {
      let otpDetails = Object.assign({}, this.state.otpDetails);
      otpDetails[name]._value = value;
      otpDetails[name].touched = true;
      otpDetails[name].errorMessage = validationMessage;

      if (event.target.name === "otp" && otpDetails["otp"].errorMessage !== "")
        otpDetails[name].errorMessage = "6 digit OTP is allowed";

      return otpDetails;
    });
  }
  handleFetchVehicleDetailChange(event) {
    let { name, value } = event.target;
    this.setState((prevState) => {
      let fetchVehicleDetails = Object.assign(
        {},
        this.state.fetchVehicleDetails
      );
      fetchVehicleDetails[name]._value = value;
      fetchVehicleDetails[name].touched = true;
      return fetchVehicleDetails;
    });
  }
  handleVehicleRegistrationWithoutChassisNumberDetailChange(event) {
    this.setState((prevState) => {
      let vehicleRegistrationWithoutChassisNumber = Object.assign(
        {},
        this.state.vehicleRegistrationWithoutChassisNumber
      );
      if (!event.target) {
        vehicleRegistrationWithoutChassisNumber["model"]._value = event;
        vehicleRegistrationWithoutChassisNumber["model"].touched = true;
      } else if (event.target.name === "filter") {
        ////console.log('event', event.target.value, event.target.name)
        let { value } = event.target;
        vehicleRegistrationWithoutChassisNumber["model"]._value = value;
        vehicleRegistrationWithoutChassisNumber["model"].touched = true;
      } else {
        ////console.log('event', event.target.value, event.target.name)
        let { name, value } = event.target;
        vehicleRegistrationWithoutChassisNumber[name]._value = value;
        vehicleRegistrationWithoutChassisNumber[name].touched = true;
      }
      return vehicleRegistrationWithoutChassisNumber;
    });
  }
  // handleVehicleRegistrationWithoutChassisNumberDetailChange(event) {

  //   ////console.log('vehicleRegistrationWithoutChassisNumber')

  //   this.setState(prevState => {
  //     let vehicleRegistrationWithoutChassisNumber = Object.assign({}, this.state.vehicleRegistrationWithoutChassisNumber);

  //     if (!event.target) {
  //       vehicleRegistrationWithoutChassisNumber['dateOfRegistration']._value = event;
  //       vehicleRegistrationWithoutChassisNumber['dateOfRegistration'].touched = true;

  //     }
  //     else {
  //       let { name, value } = event.target;
  //       vehicleRegistrationWithoutChassisNumber[name]._value = value;
  //       vehicleRegistrationWithoutChassisNumber[name].touched = true;
  //     }
  //     return vehicleRegistrationWithoutChassisNumber;
  //   })

  // };
  handleVehicleRegistrationWithoutChassisNumber2DetailChange(event) {
    ////console.log('vehicleRegistrationWithoutChassisNumber2')
    let { name, value } = event.target;
    this.setState((prevState) => {
      let vehicleRegistrationWithoutChassisNumber2 = Object.assign(
        {},
        this.state.vehicleRegistrationWithoutChassisNumber2
      );
      vehicleRegistrationWithoutChassisNumber2[name]._value = value;
      vehicleRegistrationWithoutChassisNumber2[name].touched = true;
      return vehicleRegistrationWithoutChassisNumber2;
    });
  }
  handleVehicleRegistrationWithoutChassisNumber3DetailChange(event) {
    ////console.log('vehicleRegistrationWithoutChassisNumber3', event)
    this.setState((prevState) => {
      let vehicleRegistrationWithoutChassisNumber3 = Object.assign(
        {},
        this.state.vehicleRegistrationWithoutChassisNumber3
      );
      // ////console.log(vehicleRegistrationWithoutChassisNumber3['dLIssuedDates']._value,name)
      if (!event.target) {
        vehicleRegistrationWithoutChassisNumber3["dLIssuedDates"]._value =
          event;
        vehicleRegistrationWithoutChassisNumber3[
          "dLIssuedDates"
        ].touched = true;
      } else {
        let { name, value } = event.target;

        vehicleRegistrationWithoutChassisNumber3[name]._value = value;
        vehicleRegistrationWithoutChassisNumber3[name].touched = true;
      }
      return vehicleRegistrationWithoutChassisNumber3;
    });
  }
  handledocumentMulkiyaFChange(event) {
    this.setState((prevState) => {
      let documentDetailsMulkiya = Object.assign(
        {},
        this.state.documentDetailsMulkiya
      );
      documentDetailsMulkiya["documentMulkiyaF"]._value1 = event;
      documentDetailsMulkiya["documentMulkiyaF"].touched1 = true;
      documentDetailsMulkiya["documentMulkiyaF"].errorMsg = "";
      return documentDetailsMulkiya;
    });
  }
  handledocumentMulkiyaBChange(event) {
    this.setState((prevState) => {
      let documentDetailsMulkiya = Object.assign(
        {},
        this.state.documentDetailsMulkiya
      );
      documentDetailsMulkiya["documentMulkiyaB"]._value2 = event;
      documentDetailsMulkiya["documentMulkiyaB"].touched2 = true;
      documentDetailsMulkiya["documentMulkiyaB"].errorMsg = "";
      return documentDetailsMulkiya;
    });
  }
  handledocumentLicenceFChange(event) {
    this.setState((prevState) => {
      let documentDetailsLicence = Object.assign(
        {},
        this.state.documentDetailsLicence
      );
      documentDetailsLicence["documentLicenceF"]._value1 = event;
      documentDetailsLicence["documentLicenceF"].touched1 = true;
      documentDetailsLicence["documentLicenceF"].errorMsg = "";
      return documentDetailsLicence;
    });
  }
  handledocumentLicenceBChange(event) {
    this.setState((prevState) => {
      let documentDetailsLicence = Object.assign(
        {},
        this.state.documentDetailsLicence
      );
      documentDetailsLicence["documentLicenceB"]._value2 = event;
      documentDetailsLicence["documentLicenceB"].touched2 = true;
      documentDetailsLicence["documentLicenceB"].errorMsg = "";
      return documentDetailsLicence;
    });
  }
  handledocumentEmiratesIDFChange(event) {
    this.setState((prevState) => {
      let documentDetailsEmiratesID = Object.assign(
        {},
        this.state.documentDetailsEmiratesID
      );
      documentDetailsEmiratesID["documentEmiratesIDF"]._value1 = event;
      documentDetailsEmiratesID["documentEmiratesIDF"].touched1 = true;
      documentDetailsEmiratesID["documentEmiratesIDF"].errorMsg = "";
      return documentDetailsEmiratesID;
    });
  }
  handledocumentEmiratesIDBChange(event) {
    this.setState((prevState) => {
      let documentDetailsEmiratesID = Object.assign(
        {},
        this.state.documentDetailsEmiratesID
      );
      documentDetailsEmiratesID["documentEmiratesIDB"]._value2 = event;
      documentDetailsEmiratesID["documentEmiratesIDB"].touched2 = true;
      documentDetailsEmiratesID["documentEmiratesIDB"].errorMsg = "";
      return documentDetailsEmiratesID;
    });
  }
  handlefileDetailEmiratesIDChange(event) {
    let { name, value } = event.target;
    this.setState((prevState) => {
      let fileDetailsEmiratesID = Object.assign(
        {},
        this.state.fileDetailsEmiratesID
      );
      fileDetailsEmiratesID[name]._value = value;
      fileDetailsEmiratesID[name].touched = true;
      return fileDetailsEmiratesID;
    });
  }
  handlefileDetailMulkiyaChange(event) {
    ////console.log(event)
    this.setState((prevState) => {
      let fileDetailsMulkiya = Object.assign({}, this.state.fileDetailsMulkiya);

      let { name, value } = event.target;
      fileDetailsMulkiya[name]._value = value;
      fileDetailsMulkiya[name].touched = true;

      return fileDetailsMulkiya;
    });
  }
  handlefileDetailMulkiyaRegDateChange(event) {
    this.setState((prevState) => {
      let fileDetailsMulkiya = Object.assign({}, this.state.fileDetailsMulkiya);

      fileDetailsMulkiya["regDate"]._value = event;
      fileDetailsMulkiya["regDate"].touched = true;

      return fileDetailsMulkiya;
    });
  }
  handlefileDetailMulkiyaInsDateChange(event) {
    this.setState((prevState) => {
      let fileDetailsMulkiya = Object.assign({}, this.state.fileDetailsMulkiya);

      fileDetailsMulkiya["insExp"]._value = event;
      fileDetailsMulkiya["insExp"].touched = true;

      return fileDetailsMulkiya;
    });
  }
  handlefileDetailMulkiyaExpDateChange(event) {
    this.setState((prevState) => {
      let fileDetailsMulkiya = Object.assign({}, this.state.fileDetailsMulkiya);

      fileDetailsMulkiya["expDate"]._value = event;
      fileDetailsMulkiya["expDate"].touched = true;

      return fileDetailsMulkiya;
    });
  }
  handlefileDetailMulkiyaInsExpChange(event) {
    this.setState((prevState) => {
      let fileDetailsMulkiya = Object.assign({}, this.state.fileDetailsMulkiya);

      fileDetailsMulkiya["insExp"]._value = event;
      fileDetailsMulkiya["insExp"].touched = true;

      return fileDetailsMulkiya;
    });
  }
  handlefileDetailLicenceChange(event) {
    let { name, value } = event.target;
    this.setState((prevState) => {
      let fileDetailsLicence = Object.assign({}, this.state.fileDetailsLicence);
      fileDetailsLicence[name]._value = value;
      fileDetailsLicence[name].touched = true;
      return fileDetailsLicence;
    });
  }
  handleVehicleDetailsValidations() {
    let error = false;

    let vehicle = this.state.vehicleDetails;
    for (var i in vehicle) {
      if (
        vehicle[i].required &&
        !vehicle[i]._value &&
        vehicle[i].errorMessage !== ""
      ) {
        error = true;
        ////console.log('validations', error, vehicle[i].required, vehicle[i]._value, vehicle[i].errorMessage)
      }
      if (error) return error;
    }
    return error;
  }
  handlePersonalDetailsValidations() {
    let error = false;

    let personal = this.state.personalDetails;
    for (var i in personal) {
      ////console.log('validation', personal[i].errorMessage)
      if (
        personal[i].required &&
        !personal[i]._value &&
        personal[i].errorMessage !== ""
      ) {
        error = true;
      }
      if (error) return error;
    }
    return error;
  }
  handleVehicleRegistrationWithoutChassisNumberDetailsValidations() {
    let error = false;

    let vehicleRegistrationWithoutChassis =
      this.state.vehicleRegistrationWithoutChassisNumber;
    for (var i in vehicleRegistrationWithoutChassis) {
      if (
        vehicleRegistrationWithoutChassis[i].required &&
        !vehicleRegistrationWithoutChassis[i]._value &&
        vehicleRegistrationWithoutChassis[i].errorMessage !== ""
      ) {
        error = true;
      }
      if (error) return error;
    }
    return error;
  }
  handleVehicleRegistrationWithoutChassisNumber3DetailsValidations() {
    let error = false;

    let vehicleRegistrationWithoutChassisNumber3 =
      this.state.vehicleRegistrationWithoutChassisNumber3;
    for (var i in vehicleRegistrationWithoutChassisNumber3) {
      if (
        vehicleRegistrationWithoutChassisNumber3[i].required &&
        !vehicleRegistrationWithoutChassisNumber3[i]._value &&
        vehicleRegistrationWithoutChassisNumber3[i].errorMessage !== ""
      ) {
        error = true;
      }
      if (error) return error;
    }
    return error;
  }
  handleVehicleRegistrationWithoutChassisNumber2DetailsValidations() {
    let error = false;

    let vehicleRegistrationWithoutChassis2 =
      this.state.vehicleRegistrationWithoutChassisNumber2;
    for (var i in vehicleRegistrationWithoutChassis2) {
      if (
        vehicleRegistrationWithoutChassis2[i].required &&
        !vehicleRegistrationWithoutChassis2[i]._value &&
        vehicleRegistrationWithoutChassis2[i].errorMessage !== ""
      ) {
        error = true;
      }
      if (error) return error;
    }
    return error;
  }
  handleFetchVehicleDetailsValidations() {
    let error = false;

    let fetchVehicle = this.state.fetchVehicleDetails;
    for (var i in fetchVehicle) {
      if (
        fetchVehicle[i].required &&
        !fetchVehicle[i]._value &&
        fetchVehicle[i].errorMessage !== ""
      ) {
        error = true;
      }
      if (error) return error;
    }
    return error;
  }
  handleotpDetailsValidations() {
    let error = false;

    let otp = this.state.otpDetails;
    for (var i in otp) {
      if (otp[i].required && !otp[i]._value && otp[i].errorMessage !== "") {
        error = true;
      }
      if (error) return error;
    }
    return error;
  }
  handleDocumentDetailsValidationsMulkiya() {
    let error = false;
    if (!this.state.documentDetailsMulkiya.documentMulkiyaF.touched1)
      if (
        this.state.documentDetailsMulkiya.documentMulkiyaF.required &&
        !this.state.documentDetailsMulkiya.documentMulkiyaF._value1.files
          .length > 0
      ) {
        error = true;
      } else error = false;

    if (!this.state.documentDetailsMulkiya.documentMulkiyaB.touched2)
      if (
        this.state.documentDetailsMulkiya.documentMulkiyaB.required &&
        !this.state.documentDetailsMulkiya.documentMulkiyaB._value2.files
          .length > 0
      ) {
        error = true;
      } else error = false;
    if (error) return error;

    return error;
  }
  handleDocumentDetailsValidationsLicence() {
    let error = false;
    if (!this.state.documentDetailsLicence.documentLicenceF.touched1)
      if (
        this.state.documentDetailsLicence.documentLicenceF.required &&
        !this.state.documentDetailsLicence.documentLicenceF._value1.files
          .length > 0
      ) {
        error = true;
      } else error = false;

    if (!this.state.documentDetailsLicence.documentLicenceB.touched2)
      if (
        this.state.documentDetailsLicence.documentLicenceB.required &&
        !this.state.documentDetailsLicence.documentLicenceB._value2.files
          .length > 0
      ) {
        error = true;
      } else error = false;
    if (error) return error;

    return error;
  }
  handleDocumentDetailsValidationsEmiratesID() {
    let error = false;
    if (!this.state.documentDetailsEmiratesID.documentEmiratesIDF.touched1)
      if (
        this.state.documentDetailsEmiratesID.documentEmiratesIDF.required &&
        !this.state.documentDetailsEmiratesID.documentEmiratesIDF._value1.files
          .length > 0
      ) {
        error = true;
      } else error = false;

    if (!this.state.documentDetailsEmiratesID.documentEmiratesIDB.touched2)
      if (
        this.state.documentDetailsEmiratesID.documentEmiratesIDB.required &&
        !this.state.documentDetailsEmiratesID.documentEmiratesIDB._value2.files
          .length > 0
      ) {
        error = true;
      } else error = false;
    if (error) return error;

    return error;
  }

  handlefileDetailsValidationsMulkiya() {
    let error = false;

    let file = this.state.fileDetailsMulkiya;
    for (var i in file) {
      if (file[i].required && !file[i]._value) {
        ////console.log('test', i)
        error = true;
      }
      if (error) return error;
    }
    return error;
  }
  handlefileDetailsValidationsLicence() {
    let error = false;

    let file = this.state.fileDetailsLicence;
    for (var i in file) {
      if (file[i].required && !file[i]._value) {
        ////console.log('test', i)
        error = true;
      }
      if (error) return error;
    }
    return error;
  }
  handlefileDetailsValidationsEmiratesID() {
    let error = false;

    let file = this.state.fileDetailsEmiratesID;
    for (var i in file) {
      if (file[i].required && !file[i]._value) {
        ////console.log('test', i)
        error = true;
      }
      if (error) return error;
    }
    return error;
  }

  handleChangedlEmirateDate = (evt, date) => {
    this.state.fileDetailsMulkiya.regDate._value = date;
    let fileDetailMulkiya = Object.assign({}, this.state.fileDetailsMulkiya);

    this.setState({
      fileDetailsMulkiya: fileDetailMulkiya,
    });
  };
  resetDocumentsMulkiya() {
    this.state.documentDetailsMulkiya.documentMulkiyaF._value1 = "";
    this.state.documentDetailsMulkiya.documentMulkiyaF.touched1 = false;
    this.state.documentDetailsMulkiya.documentMulkiyaB._value2 = "";
    this.state.documentDetailsMulkiya.documentMulkiyaB.touched2 = false;
    let documentDetail = Object.assign({}, this.state.documentDetailsMulkiya);
    this.setState({
      documentDetailsMulkiya: documentDetail,
    });
  }
  resetDocumentsLicence() {
    this.state.documentDetailsLicence.documentLicenceF._value1 = "";
    this.state.documentDetailsLicence.documentLicenceF.touched1 = false;
    this.state.documentDetailsLicence.documentLicenceB._value2 = "";
    this.state.documentDetailsLicence.documentLicenceB.touched2 = false;
    let documentDetail = Object.assign({}, this.state.documentDetailsLicence);
    this.setState({
      documentDetailsLicence: documentDetail,
    });
  }
  resetDocumentsEmiratesID() {
    this.state.documentDetailsEmiratesID.documentEmiratesIDF._value1 = "";
    this.state.documentDetailsEmiratesID.documentEmiratesIDF.touched1 = false;
    this.state.documentDetailsEmiratesID.documentEmiratesIDB._value2 = "";
    this.state.documentDetailsEmiratesID.documentEmiratesIDB.touched2 = false;
    let documentDetail = Object.assign(
      {},
      this.state.documentDetailsEmiratesID
    );
    this.setState({
      documentDetailsEmiratesID: documentDetail,
    });
  }
  DBDateFormat(date) {
    if (!date) {
      date = "";
      return date;
    }

    //console.log(date)
    // date = new Date(date).toLocaleDateString();
    date = date.split("/");
    // date = (date[0] < 10 ? "0" + date[0] : date[0]) + "/" + (date[1] < 10 ? "0" + date[1] : date[1]) + "/" + date[2];
    date =
      (date[0] < 10 ? "0" + date[0] : date[0]) +
      "/" +
      (date[1] < 10 ? "0" + date[1] : date[1]) +
      "/" +
      date[2];

    return date;
  }
  DBDateOfRegistrationFormat(date) {
    if (!date) {
      date = "";
      return date;
    }

    date = date.day + "/" + date.month + "/" + date.year;
    //console.log(date)
    // date = new Date(date).toLocaleDateString();
    date = date.split("/");
    // date = (date[0] < 10 ? "0" + date[0] : date[0]) + "/" + (date[1] < 10 ? "0" + date[1] : date[1]) + "/" + date[2];
    date =
      (date[0] < 10 ? "0" + date[0] : date[0]) +
      "/" +
      (date[1] < 10 ? "0" + date[1] : date[1]) +
      "/" +
      date[2];

    return date;
  }
  getMulkiyaDetails() {
    if (this.state.currentStep === 3) {
      this.setState({ loader: true });

      this.setState({
        submitDocumentDetailsMulkiya: true,
      });
      this.state.submitDocumentDetailsMulkiya = true;
      if (this.handleDocumentDetailsValidationsMulkiya()) {
        return;
      }
      if (this.state.submitDocumentDetailsMulkiya) {
        this.setState({ loader: true });
        let post = {
          headers: new Headers({
            Accept: "text/plain;charset=UTF-8",
            "Content-Type": "text/plain;charset=UTF-8",
            "Access-Control-Allow-Origin": this.state.https,
            Authorization: `Bearer ${localStorage.getItem("user")}`,
          }),
          mode: "no-cors",
          data: {
            UserName: this.state.personalDetails.email._value,
            FileGroup: "Mulkiya",
            PlateCode: this.state.vehicleDetails.plateCode._value,
            PlateNumber: this.state.vehicleDetails.plateNumber._value,
            OCRFiles: [
              {
                FileType: "Mulkiya-F",
                File: this.state.documentDetailsMulkiya.documentMulkiyaF._value1.dataURL.replace(
                  "data:image/png;base64,",
                  ""
                ),
                FileExtesnion:
                  this.state.documentDetailsMulkiya.documentMulkiyaF._value1
                    .type,
                FileName:
                  this.state.documentDetailsMulkiya.documentMulkiyaF._value1
                    .name,
              },
              {
                FileType: "Mulkiya-B",
                File: this.state.documentDetailsMulkiya.documentMulkiyaB._value2.dataURL.replace(
                  "data:image/png;base64,",
                  ""
                ),
                FileExtesnion:
                  this.state.documentDetailsMulkiya.documentMulkiyaB._value2
                    .type,
                FileName:
                  this.state.documentDetailsMulkiya.documentMulkiyaB._value2
                    .name,
              },
            ],
          },
        };
        this.state.responseMsg = "";
        axios
          .post(this.state.api + `Vehicle/UploadOCRDocument`, post.data)
          .then((response) => {
            // this.state.responseMsg = "Uploaded And Fetched OCR Details Successfully";
            if (response.status === 200 && response.data.Result === "Success") {
            } else {
              // this.state.responseMsg = "Failed to Fetch OCR Details";
            }

            ////console.log(response, response.data.Message, response.data.Result)
            this.resetDocumentsMulkiya();

            if (
              response &&
              response.status === 200 &&
              response.data.Result !== "Failure" &&
              (response.data.Message !==
                "Error Occured While Executing UploadOCRDocument" ||
                response.data.ErrorDesc !== "Failed to upload file.")
            ) {
              this.state.fileDetailsMulkiya.plateNo._value =
                response.data.PlateNumber !== null
                  ? response.data.PlateNumber
                  : "";
              this.state.fileDetailsMulkiya.documentDetailsID._value =
                response.data.DocumentDetailsID !== null
                  ? response.data.DocumentDetailsID
                  : "";
              this.state.fileDetailsMulkiya.plateCategory._value =
                response.data.PlateCode !== null ? response.data.PlateCode : "";
              this.state.fileDetailsMulkiya.insExp._value =
                response.data.Mulkiya.InsExpDate !== null
                  ? response.data.Mulkiya.InsExpDate
                  : "";
              this.state.fileDetailsMulkiya.regDate._value =
                response.data.Mulkiya.VehicleRegDate !== null
                  ? response.data.Mulkiya.VehicleRegDate
                  : "";
              this.state.fileDetailsMulkiya.chassisNumber._value =
                response.data.Mulkiya.ChassisNo !== null
                  ? response.data.Mulkiya.ChassisNo
                  : "";
              this.state.fileDetailsMulkiya.engineNumber._value =
                response.data.Mulkiya.EngineNo !== null
                  ? response.data.Mulkiya.EngineNo
                  : "";
              this.state.fileDetailsMulkiya.engineSize._value =
                response.data.Mulkiya.EngineNo !== null
                  ? response.data.Mulkiya.EngineNo
                  : "";
              this.state.fileDetailsMulkiya.expDate._value =
                response.data.Mulkiya.InsExpDate !== null
                  ? response.data.Mulkiya.InsExpDate
                  : "";
              this.state.fileDetailsMulkiya.trim._value = "";
              this.state.fileDetailsMulkiya.cylinder._value = "";
              let fileDetailMulkiya = Object.assign(
                {},
                this.state.fileDetailsMulkiya
              );

              this.setState({
                fileDetailsMulkiya: fileDetailMulkiya,
                loader: false,
              });
            } else {
              this.setState({
                loader: false,
              });
            }
            return response;
          })
          .catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              this.setState({ loader: false, smShow: true });
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
              this.setState({ loader: false, smShow: true });
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              this.setState({ loader: false, smShow: true });
            }
          });
      }
    }
  }
  _next() {
    if (this.state.currentStep === 1) {
      this.setState({
        submitVehicleRegistrationWithoutChassisNumberDetails: true,
      });
      this.state.submitVehicleRegistrationWithoutChassisNumberDetails = true;
      if (
        this.handleVehicleRegistrationWithoutChassisNumberDetailsValidations()
      ) {
        return;
      }
      this.accordionShow2();
      this.accordionShow();
      localStorage.setItem(
        "VehicleRegistrationWithoutChassisNumber",
        JSON.stringify(this.state.vehicleRegistrationWithoutChassisNumber)
      );
      this.setState({ loader: false });
    } else if (this.state.currentStep === 2) {
      localStorage.setItem(
        "VehicleDetails",
        JSON.stringify(this.state.vehicleDetails)
      );
      localStorage.setItem(
        "PersonalDetails",
        JSON.stringify(this.state.personalDetails)
      );

      this.setState({
        submitPersonalDetails: true,
      });
      this.state.submitPersonalDetails = true;
      this.setState({
        submitVehicleDetails: true,
      });
      this.state.submitVehicleDetails = true;
      if (this.handleVehicleDetailsValidations()) return;
      if (this.handlePersonalDetailsValidations()) return;
      if (this.state.submitVehicleDetails) {
        this.setState({ loader: true, closePopup: "show" });
        let post = {
          headers: new Headers({
            Accept: "text/plain;charset=UTF-8",
            "Content-Type": "text/plain;charset=UTF-8",
            "Access-Control-Allow-Origin": this.state.https,
            Authorization: `Bearer ${localStorage.getItem("user")}`,
            // <-- Specifying the Content-Type
          }),
          mode: "no-cors",
          data: {
            plateCode: this.state.vehicleDetails.plateCode._value,
            plateNumber: this.state.vehicleDetails.plateNumber._value,
            vehicleId: this.state.vehicleDetails.vehicleId._value,
            vehiclecol: this.state.vehicleDetails.vehiclecol._value,
            vehicleDetailsID: this.state.vehicleDetails.vehicleDetailsID._value,
            chassisNo: this.state.vehicleDetails.chassisNo._value,
            isActive: this.state.vehicleDetails.isActive._value,
          },
        };
        axios
          .post(
            this.state.api + `Vehicle/GetVehicleRegistrationStatus`,
            post.data
          )
          .then((response) => {
            // debugger;
            if (
              response.status === 200 &&
              response.data.Status === "Success" &&
              response.data.IsVehicleRegistered === true
            ) {
              // this.state.currentStep = 3;
              console.log("IsVehicleRegistered = True");
              //  debugger;
              if (
                this.state.vehicleRegistrationWithoutChassisNumber.gcc
                  ._value === "false"
              ) {
                this.AddCustomer("Non GCC vehicle");
                this.state.responseMsg =
                  "Thank you for the details, our representative will contact you soon";
                this.setState({ loader: false, closePopup: "", smShow: true });
              } else {
                let post = {
                  headers: new Headers({
                    Accept: "text/plain;charset=UTF-8",
                    "Content-Type": "text/plain;charset=UTF-8",
                    "Access-Control-Allow-Origin": this.state.https,
                    Authorization: `Bearer ${localStorage.getItem("user")}`,
                  }),
                  mode: "no-cors",
                  data: {
                    Make: this.state.vehicleRegistrationWithoutChassisNumber
                      .make._value,
                    Model:
                      this.state.vehicleRegistrationWithoutChassisNumber.model
                        ._value,
                    ModelYear:
                      this.state.vehicleRegistrationWithoutChassisNumber
                        .modelYear._value,
                    // "trimValue": 'GLS',
                    trimValue:
                      this.state.vehicleRegistrationWithoutChassisNumber
                        .trimValue._value === ""
                        ? "GLS"
                        : this.state.vehicleRegistrationWithoutChassisNumber
                            .trimValue._value,
                    BodyType:
                      this.state.vehicleRegistrationWithoutChassisNumber
                        .bodyType._value,
                    DateOfRegistration: this.DBDateOfRegistrationFormat(
                      this.state.vehicleRegistrationWithoutChassisNumber
                        .dateOfRegistration._value
                    ),
                    // "NoClaimYear":      this.state.vehicleRegistrationWithoutChassisNumber.noClaimYear._value,
                    NoClaimYear: "2",
                    PlateCode: this.state.vehicleDetails.plateCode._value,
                    PlateNumber: this.state.vehicleDetails.plateNumber._value,
                    LOCRegion:
                      this.state.vehicleRegistrationWithoutChassisNumber
                        .dLEmirate._value,
                    EngineSize:
                      this.state.vehicleRegistrationWithoutChassisNumber
                        .engineSize._value,
                    PolicyStartDate: this.DBDateOfRegistrationFormat(
                      this.state.vehicleRegistrationWithoutChassisNumber
                        .dateOfRegistration._value
                    ),
                    UserName: response.data.UserName,
                    // "VehicleValue": '100000',
                    VehicleValue: round100(
                      this.state.vehicleRegistrationWithoutChassisNumber
                        .vehicleValue._value
                    ).toString(),
                    DLIssuedDate: this.DBDateOfRegistrationFormat(
                      this.state.vehicleRegistrationWithoutChassisNumber
                        .dLIssuedDate._value
                    ),
                    DLEmirate:
                      this.state.vehicleRegistrationWithoutChassisNumber
                        .dLEmirate._value,
                    // "DLEmirate": 'Dubai'
                  },
                };

                this.state.responseMsg = "";
                //  debugger;
                axios
                  .post(
                    this.state.api +
                      `Vehicle/VehicleRegistrationWithoutChassisNo`,
                    post.data
                  )
                  .then((response) => {
                    if (response) {
                      let post = {
                        headers: new Headers({
                          Accept: "text/plain;charset=UTF-8",
                          "Content-Type": "text/plain;charset=UTF-8",
                          "Access-Control-Allow-Origin": this.state.https,
                          Authorization: `Bearer ${localStorage.getItem(
                            "user"
                          )}`,
                        }),
                        mode: "no-cors",
                        data: {
                          vehicleDetailsID: response.data.Message,
                        },
                      };

                      if (
                        response.data.Message !== "" &&
                        response.data.Message !== null
                      ) {
                        // this.setState({ });

                        axios
                          .post(
                            this.state.api + `Vehicle/GetMotorQuotes`,
                            post.data
                          )
                          .then((response) => {
                            if (
                              response.data.Message !== "" &&
                              response.data.Message !== null
                            ) {
                              let vehicleDetailsId = response.data;
                              vehicleDetailsId = vehicleDetailsId.replace(
                                /\\/g,
                                ""
                              );
                              vehicleDetailsId = JSON.parse(vehicleDetailsId);
                              vehicleDetailsId = vehicleDetailsId.QuoteList;
                              //  debugger;
                              let finalQuotes = [];
                              if (vehicleDetailsId.length > 0) {
                                this.state.quoteList.push(vehicleDetailsId);
                                if (this.state.quoteList.length) {
                                  for (let quotes of this.state.quoteList) {
                                    for (let i = 0; i < quotes.length; i++) {
                                      let quote = {
                                        InsQuoteID: quotes[i].InsQuoteID,
                                        InsName: quotes[i].InsName,
                                        VehicleValue: quotes[i].VehicleValue,
                                        NetPremium: quotes[i].NetPremium,
                                        PlanName: quotes[i].PlanName,
                                        Premium: quotes[i].Premium,
                                        CoverageType: quotes[i].CoverageType,
                                      };
                                      finalQuotes.push(quote);
                                    }

                                    if (this.state.quoteList.length <= 0) {
                                      this.state.responseMsg = "No Quote List";
                                      this.setState((state, props) => {
                                        return {
                                          loader: false,
                                          closePopup: "",
                                          smShow: true,
                                        };
                                      });
                                    }

                                    axios
                                      .post(api + `Admin/AddCustomer`, {
                                        customerId: 0,
                                        bodyType:
                                          this.state
                                            .vehicleRegistrationWithoutChassisNumber
                                            .bodyType._value,
                                        dLEmirate:
                                          this.state
                                            .vehicleRegistrationWithoutChassisNumber
                                            .dLEmirate._value,
                                        dLIssuedDate:
                                          this.state
                                            .vehicleRegistrationWithoutChassisNumber
                                            .dLIssuedDate._values,
                                        dateOfRegistration:
                                          this.state
                                            .vehicleRegistrationWithoutChassisNumber
                                            .dateOfRegistration._values,
                                        engineSize:
                                          this.state
                                            .vehicleRegistrationWithoutChassisNumber
                                            .engineSize._value,
                                        lOCRegion:
                                          this.state
                                            .vehicleRegistrationWithoutChassisNumber
                                            .dLEmirate._value,
                                        make: this.state
                                          .vehicleRegistrationWithoutChassisNumber
                                          .make._value,
                                        model:
                                          this.state
                                            .vehicleRegistrationWithoutChassisNumber
                                            .model._value,
                                        modelYear:
                                          this.state
                                            .vehicleRegistrationWithoutChassisNumber
                                            .modelYear._value,
                                        noClaimYear: "2",
                                        plateCode:
                                          this.state.vehicleDetails.plateCode
                                            ._value,
                                        plateNumber: parseInt(
                                          this.state.vehicleDetails.plateNumber
                                            ._value
                                        ),
                                        policyStartDate:
                                          this.state
                                            .vehicleRegistrationWithoutChassisNumber
                                            .dateOfRegistration._values,
                                        vehicleValue: String(
                                          round100(
                                            this.state
                                              .vehicleRegistrationWithoutChassisNumber
                                              .vehicleValue._value
                                          )
                                        ),
                                        vehiclePrice:
                                          this.state
                                            .vehicleRegistrationWithoutChassisNumber
                                            .vehicleTxtPrice._value,
                                        trimValue:
                                          this.state
                                            .vehicleRegistrationWithoutChassisNumber
                                            .trimValue._value,
                                        yourName:
                                          this.state.personalDetails.yourName
                                            ._value,
                                        mobileNumber:
                                          this.state.personalDetails
                                            .mobileNumber._value,
                                        email:
                                          this.state.personalDetails.email
                                            ._value,
                                        userID:
                                          this.props.location.search !== ""
                                            ? parseInt(
                                                this.props.location.search.substring(
                                                  9
                                                )
                                              )
                                            : parseInt(0),
                                        vehicleDetailsID: vehicleDetailsId
                                          ? vehicleDetailsId[0].VehicleDetailsID
                                          : "0",
                                        quoteId: "0",
                                        stage: "Initial",
                                        comments: "",
                                      })
                                      .then((response) => {
                                        //  debugger;
                                        if (response) {
                                          this.setState((prevState) => {
                                            let vehicleId = Object.assign(
                                              {},
                                              prevState.vehicleId
                                            );
                                            this.state.vehicleId =
                                              response.data;
                                            //  vehicleId = response.data;
                                            this.props.history.push({
                                              pathname: "/insurancelist",
                                              state: {
                                                staffID: this.state.staffID,
                                                quoteList:
                                                  this.state.quoteList[0],
                                                vehicleDetails:
                                                  this.state.vehicleDetails,
                                                vehicleId: this.state.vehicleId,
                                                vehicleRegistrationWithoutChassisNumber:
                                                  this.state
                                                    .vehicleRegistrationWithoutChassisNumber,
                                                personalDetails:
                                                  this.state.personalDetails,
                                              },
                                            });
                                            return { vehicleId: vehicleId };
                                          });
                                        }
                                      });
                                  }
                                }
                                this.setState({
                                  loader: false,
                                  closePopup: "",
                                  vehicleRegistration: true,
                                });
                                // this.state.responseMsg = response.data.Message;
                              } else {
                                this.state.quoteList.push(vehicleDetailsId);
                                ////console.log(vehicleDetailsId)
                                this.state.responseMsg = "No Quote List";

                                this.setState((state, props) => {
                                  return {
                                    loader: false,
                                    closePopup: "",
                                    smShow: true,
                                  };
                                });
                              }
                              if (this.state.quoteList.length > 0) {
                                this.setState({
                                  vehicleDetailsIDs: finalQuotes,
                                  loader: false,
                                  closePopup: "",
                                  smShow: false,
                                });
                              }
                            }
                          })
                          .catch(function (error) {
                            if (error.response) {
                              // Request made and server responded
                              console.log(error.response.data);
                              console.log(error.response.status);
                              console.log(error.response.headers);
                              this.setState({
                                loader: false,
                                closePopup: "",
                                smShow: true,
                              });
                            } else if (error.request) {
                              // The request was made but no response was received
                              console.log(error.request);
                              this.setState({
                                loader: false,
                                closePopup: "",
                                smShow: true,
                              });
                            } else {
                              // Something happened in setting up the request that triggered an Error
                              console.log("Error", error.message);
                              this.setState({
                                loader: false,
                                closePopup: "",
                                smShow: true,
                              });
                            }
                          });
                      } else {
                        this.state.responseMsg = response.data.ErrorDesc;

                        this.setState({
                          loader: false,
                          closePopup: "",
                          submitVehicleRegistrationWithoutChassisNumberDetails: false,
                          smShow: true,
                        });
                        //alert(response.data.ErrorDesc)
                      }
                    } else {
                      this.state.responseMsg = response.data.ErrorDesc;

                      this.setState({
                        loader: false,
                        closePopup: "",
                        smShow: true,
                      });
                    }
                  })
                  .catch(function (error) {
                    if (error.response) {
                      // Request made and server responded
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                      this.setState({
                        loader: false,
                        closePopup: "",
                        smShow: true,
                      });
                    } else if (error.request) {
                      // The request was made but no response was received
                      console.log(error.request);
                      this.setState({
                        loader: false,
                        closePopup: "",
                        smShow: true,
                      });
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log("Error", error.message);
                      this.setState({
                        loader: false,
                        closePopup: "",
                        smShow: true,
                      });
                    }
                  });
              }
            } else if (
              response.status === 200 &&
              response.data.Status === "Success" &&
              response.data.IsVehicleRegistered === false
            ) {
              ////console.log(response, response.data.Status, response.data.Message);
              console.log("IsVehicleRegistered = False");
              //  debugger;
              if (
                this.state.submitPersonalDetails &&
                response.data.Message === "Not Registered"
              ) {
                ////console.log('Not Registered')
                let post = {
                  headers: new Headers({
                    Accept: "text/plain;charset=UTF-8",
                    "Content-Type": "text/plain;charset=UTF-8",
                    "Access-Control-Allow-Origin": this.state.https,
                    Authorization: `Bearer ${localStorage.getItem("user")}`,
                    // <-- Specifying the Content-Type
                  }),
                  mode: "no-cors",
                  data: {
                    insuredName: this.state.personalDetails.yourName._value,
                    mobileNo: this.state.personalDetails.mobileNumber._value,
                    emailID: this.state.personalDetails.email._value,
                  },
                };
                axios
                  .post(
                    this.state.api + `User/UserRegistrationByEmailID`,
                    post.data
                  )
                  .then((response) => {
                    if (
                      this.state.vehicleRegistrationWithoutChassisNumber.gcc
                        ._value === "false"
                    ) {
                      this.AddCustomer("Non GCC vehicle");
                      this.state.responseMsg =
                        "Thank you for sharing the details, our representative will contact you soon";
                      this.setState({
                        loader: false,
                        closePopup: "",
                        smShow: true,
                      });
                    } else {
                      if (response.data.Status === "Success") {
                        //alert(response.data.Message)
                        let post = {
                          headers: new Headers({
                            Accept: "text/plain;charset=UTF-8",
                            "Content-Type": "text/plain;charset=UTF-8",
                            "Access-Control-Allow-Origin": this.state.https,
                            Authorization: `Bearer ${localStorage.getItem(
                              "user"
                            )}`,
                          }),
                          mode: "no-cors",
                          data: {
                            Make: this.state
                              .vehicleRegistrationWithoutChassisNumber.make
                              ._value,
                            Model:
                              this.state.vehicleRegistrationWithoutChassisNumber
                                .model._value,
                            ModelYear:
                              this.state.vehicleRegistrationWithoutChassisNumber
                                .modelYear._value,
                            trimValue:
                              this.state.vehicleRegistrationWithoutChassisNumber
                                .trimValue._value === ""
                                ? "GLS"
                                : this.state
                                    .vehicleRegistrationWithoutChassisNumber
                                    .trimValue._value,
                            BodyType:
                              this.state.vehicleRegistrationWithoutChassisNumber
                                .bodyType._value === "MPV"
                                ? "SUV"
                                : this.state
                                    .vehicleRegistrationWithoutChassisNumber
                                    .bodyType._value,
                            DateOfRegistration: this.DBDateOfRegistrationFormat(
                              this.state.vehicleRegistrationWithoutChassisNumber
                                .dateOfRegistration._value
                            ),
                            NoClaimYear: "2",
                            PlateCode:
                              this.state.vehicleDetails.plateCode._value,
                            PlateNumber:
                              this.state.vehicleDetails.plateNumber._value,
                            LOCRegion:
                              this.state.vehicleRegistrationWithoutChassisNumber
                                .dLEmirate._value,
                            EngineSize:
                              this.state.vehicleRegistrationWithoutChassisNumber
                                .engineSize._value,
                            PolicyStartDate: this.DBDateOfRegistrationFormat(
                              this.state.vehicleRegistrationWithoutChassisNumber
                                .dateOfRegistration._value
                            ),
                            UserName: this.state.personalDetails.email._value,
                            VehicleValue: round100(
                              this.state.vehicleRegistrationWithoutChassisNumber
                                .vehiclePrice._value
                            ).toString(),
                            DLIssuedDate: this.DBDateOfRegistrationFormat(
                              this.state.vehicleRegistrationWithoutChassisNumber
                                .dLIssuedDate._value
                            ),
                            DLEmirate:
                              this.state.vehicleRegistrationWithoutChassisNumber
                                .dLEmirate._value,
                          },
                        };
                        this.state.responseMsg = "";
                        //  debugger;
                        axios
                          .post(
                            this.state.api +
                              `Vehicle/VehicleRegistrationWithoutChassisNo`,
                            post.data
                          )
                          .then((response) => {
                            if (response.data.Status === "Success") {
                              let post = {
                                headers: new Headers({
                                  Accept: "text/plain;charset=UTF-8",
                                  "Content-Type": "text/plain;charset=UTF-8",
                                  "Access-Control-Allow-Origin":
                                    this.state.https,
                                  Authorization: `Bearer ${localStorage.getItem(
                                    "user"
                                  )}`,
                                }),
                                mode: "no-cors",
                                data: {
                                  vehicleDetailsID: response.data.Message,
                                },
                              };
                              this.state.responseMsg = "";

                              // this.setState({ loader: false });
                              if (
                                response.data.Message !== "" &&
                                response.data.Message !== null
                              ) {
                                axios
                                  .post(
                                    this.state.api + `Vehicle/GetMotorQuotes`,
                                    post.data
                                  )
                                  .then((response) => {
                                    if (
                                      response.data.Message !== "" &&
                                      response.data.Message !== null
                                    ) {
                                      let vehicleDetailsId = response.data;
                                      vehicleDetailsId =
                                        vehicleDetailsId.replace(/\\/g, "");
                                      vehicleDetailsId =
                                        JSON.parse(vehicleDetailsId);
                                      vehicleDetailsId =
                                        vehicleDetailsId.QuoteList;

                                      let finalQuotes = [];
                                      if (vehicleDetailsId) {
                                        this.state.quoteList.push(
                                          vehicleDetailsId
                                        );
                                        if (this.state.quoteList.length) {
                                          ////console.log(this.state.quoteList.length)
                                          for (let quotes of this.state
                                            .quoteList) {
                                            for (
                                              let i = 0;
                                              i < quotes.length;
                                              i++
                                            ) {
                                              let quote = {
                                                InsQuoteID:
                                                  quotes[i].InsQuoteID,
                                                InsName: quotes[i].InsName,
                                                VehicleValue:
                                                  quotes[i].VehicleValue,
                                                NetPremium:
                                                  quotes[i].NetPremium,
                                                PlanName: quotes[i].PlanName,
                                                Premium: quotes[i].Premium,
                                                CoverageType:
                                                  quotes[i].CoverageType,
                                              };
                                              finalQuotes.push(quote);
                                            }

                                            if (
                                              this.state.quoteList.length <= 0
                                            ) {
                                              this.state.responseMsg =
                                                "No Quote List";
                                              this.setState((state, props) => {
                                                return {
                                                  loader: false,
                                                  closePopup: "",
                                                  smShow: true,
                                                };
                                              });
                                              // this.setState({
                                              //   loader: false,
                                              //   closePopup: "",
                                              //   smShow: true,
                                              // });
                                            }

                                            ////console.log(this.state.quoteList, finalQuotes, Quotes.length)
                                            ////console.log(this.state.quoteList, finalQuotes, Quotes)
                                            axios
                                              .post(api + `Admin/AddCustomer`, {
                                                customerId: 0,
                                                bodyType:
                                                  this.state
                                                    .vehicleRegistrationWithoutChassisNumber
                                                    .bodyType._value,
                                                dLEmirate:
                                                  this.state
                                                    .vehicleRegistrationWithoutChassisNumber
                                                    .dLEmirate._value,
                                                dLIssuedDate:
                                                  this.state
                                                    .vehicleRegistrationWithoutChassisNumber
                                                    .dLIssuedDate._values,
                                                dateOfRegistration:
                                                  this.state
                                                    .vehicleRegistrationWithoutChassisNumber
                                                    .dateOfRegistration._values,
                                                engineSize:
                                                  this.state
                                                    .vehicleRegistrationWithoutChassisNumber
                                                    .engineSize._value,
                                                lOCRegion:
                                                  this.state
                                                    .vehicleRegistrationWithoutChassisNumber
                                                    .dLEmirate._value,
                                                make: this.state
                                                  .vehicleRegistrationWithoutChassisNumber
                                                  .make._value,
                                                model:
                                                  this.state
                                                    .vehicleRegistrationWithoutChassisNumber
                                                    .model._value,
                                                modelYear:
                                                  this.state
                                                    .vehicleRegistrationWithoutChassisNumber
                                                    .modelYear._value,
                                                noClaimYear: "2",
                                                plateCode:
                                                  this.state.vehicleDetails
                                                    .plateCode._value,
                                                plateNumber: parseInt(
                                                  this.state.vehicleDetails
                                                    .plateNumber._value
                                                ),
                                                policyStartDate:
                                                  this.state
                                                    .vehicleRegistrationWithoutChassisNumber
                                                    .dateOfRegistration._values,
                                                vehicleValue: round100(
                                                  this.state
                                                    .vehicleRegistrationWithoutChassisNumber
                                                    .vehicleValue._value
                                                ),
                                                vehiclePrice: round100(
                                                  this.state
                                                    .vehicleRegistrationWithoutChassisNumber
                                                    .vehicleTxtPrice._value
                                                ),
                                                trimValue:
                                                  this.state
                                                    .vehicleRegistrationWithoutChassisNumber
                                                    .trimValue._value,
                                                yourName:
                                                  this.state.personalDetails
                                                    .yourName._value,
                                                mobileNumber:
                                                  this.state.personalDetails
                                                    .mobileNumber._value,
                                                email:
                                                  this.state.personalDetails
                                                    .email._value,
                                                userID:
                                                  this.props.location.search !==
                                                  ""
                                                    ? parseInt(
                                                        this.props.location.search.substring(
                                                          9
                                                        )
                                                      )
                                                    : parseInt(0),
                                                vehicleDetailsID:
                                                  vehicleDetailsId
                                                    ? vehicleDetailsId[0]
                                                        .VehicleDetailsID
                                                    : "0",
                                                quoteId: "0",
                                                stage: "Initial",
                                                comments: "",
                                              })
                                              .then((response) => {
                                                //  debugger;
                                                if (response) {
                                                  this.setState((prevState) => {
                                                    let vehicleId =
                                                      Object.assign(
                                                        {},
                                                        prevState.vehicleId
                                                      );
                                                    this.state.vehicleId =
                                                      response.data;
                                                    //  vehicleId = response.data;
                                                    this.props.history.push({
                                                      pathname:
                                                        "/insurancelist",
                                                      state: {
                                                        staffID:
                                                          this.state.staffID,
                                                        quoteList:
                                                          this.state
                                                            .quoteList[0],
                                                        vehicleDetails:
                                                          this.state
                                                            .vehicleDetails,
                                                        vehicleId:
                                                          this.state.vehicleId,
                                                        vehicleRegistrationWithoutChassisNumber:
                                                          this.state
                                                            .vehicleRegistrationWithoutChassisNumber,
                                                        personalDetails:
                                                          this.state
                                                            .personalDetails,
                                                      },
                                                    });
                                                    return {
                                                      vehicleId: vehicleId,
                                                    };
                                                  });
                                                }
                                              });
                                          }
                                        }
                                        this.setState({
                                          loader: false,
                                          closePopup: "",
                                          vehicleRegistration: true,
                                        });
                                        // this.state.responseMsg = response.data.Message;
                                      } else {
                                        this.state.quoteList.push(
                                          vehicleDetailsId
                                        );
                                        ////console.log(vehicleDetailsId)
                                        this.state.responseMsg = "No QuoteList";
                                        this.setState({
                                          loader: false,
                                          closePopup: "",
                                          smShow: true,
                                        });

                                        // //alert('No QuoteList')
                                      }
                                      if (this.state.quoteList.length > 0) {
                                        ////console.log(this.state.quoteList, finalQuotes)
                                        this.setState({
                                          vehicleDetailsIDs: finalQuotes,
                                          loader: false,
                                          closePopup: "",
                                          smShow: false,
                                        });
                                      }
                                    }
                                  })
                                  .catch(function (error) {
                                    if (error.response) {
                                      // Request made and server responded
                                      console.log(error.response.data);
                                      console.log(error.response.status);
                                      console.log(error.response.headers);
                                      this.setState({
                                        loader: false,
                                        closePopup: "",
                                        smShow: true,
                                      });
                                    } else if (error.request) {
                                      // The request was made but no response was received
                                      console.log(error.request);
                                      this.setState({
                                        loader: false,
                                        closePopup: "",
                                        smShow: true,
                                      });
                                    } else {
                                      // Something happened in setting up the request that triggered an Error
                                      console.log("Error", error.message);
                                      this.setState({
                                        loader: false,
                                        closePopup: "",
                                        smShow: true,
                                      });
                                    }
                                  });
                              } else {
                                this.state.responseMsg =
                                  response.data.ErrorDesc;
                                this.setState({
                                  loader: false,
                                  closePopup: "",
                                  smShow: true,
                                });
                              }
                              // else
                              //alert(response.data.ErrorDesc)
                            } else {
                              this.state.responseMsg = response.data.ErrorDesc;

                              this.setState({
                                loader: false,
                                closePopup: "",
                                smShow: true,
                              });
                            }
                          })
                          .catch(function (error) {
                            if (error.response) {
                              // Request made and server responded
                              console.log(error.response.data);
                              console.log(error.response.status);
                              console.log(error.response.headers);
                              this.setState({
                                loader: false,
                                closePopup: "",
                                smShow: true,
                              });
                            } else if (error.request) {
                              // The request was made but no response was received
                              console.log(error.request);
                              this.setState({
                                loader: false,
                                closePopup: "",
                                smShow: true,
                              });
                            } else {
                              // Something happened in setting up the request that triggered an Error
                              console.log("Error", error.message);
                              this.setState({
                                loader: false,
                                closePopup: "",
                                smShow: true,
                              });
                            }
                          });
                        if (
                          response.status === 200 &&
                          response.data.Status === "Success"
                        ) {
                          // this.state.responseMsg = response.data.Message;
                        } else {
                          this.state.responseMsg = response.data.ErrMsg;
                          this.setState({
                            loader: false,
                            closePopup: "",
                            smShow: true,
                          });
                        }
                      } else {
                        this.state.responseMsg = response.data.ErrMsg;
                        this.setState({
                          loader: false,
                          closePopup: "",
                          smShow: true,
                        });
                      }
                      return response;
                    }
                  })
                  .catch(function (error) {
                    if (error.response) {
                      // Request made and server responded
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                      this.setState({
                        loader: false,
                        closePopup: "",
                        smShow: true,
                      });
                    } else if (error.request) {
                      // The request was made but no response was received
                      console.log(error.request);
                      this.setState({
                        loader: false,
                        closePopup: "",
                        smShow: true,
                      });
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log("Error", error.message);
                      this.setState({
                        loader: false,
                        closePopup: "",
                        smShow: true,
                      });
                    }
                  });
              } else {
                this.state.responseMsg = response.data.ErrorDesc;
                this.setState({ loader: false, closePopup: "", smShow: true });
                //alert(response.data.ErrorDesc)
              }
            } else if (
              response.status === 200 &&
              response.data.Status === "Failure" &&
              response.data.IsVehicleRegistered === true
            ) {
              //Multiple Users are Present
              //  debugger;
              this.AddCustomer("Multiple Users are Present");
              this.setState({
                responseMsg:
                  "Vehicle is already registered by another user, please contact our representative to update.",
              });
              this.setState({
                loader: false,
                closePopup: "",
                smShow: true,
              });
            }
          })
          .catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              this.setState({ loader: false, closePopup: "", smShow: true });
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
              this.setState({ loader: false, closePopup: "", smShow: true });
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              this.setState({ loader: false, closePopup: "", smShow: true });
            }
          });
      }
    } else {
    }

    let currentStep = this.state.currentStep;
    ////console.log(currentStep)
    currentStep = currentStep >= 2 ? 2 : currentStep + 1;
    ////console.log(currentStep)
    this.setState({
      currentStep: currentStep,
    });
  }

  _prev = () => {
    let currentStep = this.state.currentStep;
    this.state.responseMsg = "";
    this.accordionShow();
    ////console.log('steps', currentStep)
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  };

  /*
   * the functions for our button
   */
  previousButton() {
    let currentStep = this.state.currentStep;
    // if (currentStep !== 1) {
    //   return (
    //     <button
    //       className="btn btn-secondary float-center"
    //       type="button" onClick={this._prev}>
    //       Previous
    //     </button>

    //   )
    // }
    if (currentStep === 2) {
      return (
        <button
          className="btn btn-primary float-center"
          type="button"
          onClick={this._prev}
        >
          Previous
        </button>
      );
    }
    return null;
  }

  nextButton() {
    let currentStep = this.state.currentStep;
    if (currentStep === 1) {
      return (
        <button
          className="btn btn-primary float-right"
          disabled={
            !this.state.vehicleRegistrationWithoutChassisNumber.vehicleValue
              ._value
          }
          type="button"
          onClick={this._next}
        >
          Next
        </button>
      );
    }
    if (currentStep === 2) {
      return (
        <button
          className="btn btn-primary float-right"
          disabled={!this.state.personalDetails.email._value}
          type="button"
          onClick={this._next}
        >
          Proceed
        </button>
      );
    }

    if (currentStep < 3) {
      return (
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={this._next}
        >
          Next
        </button>
      );
    }
    if (currentStep === 11) {
      return (
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={this._next}
        >
          Finish
        </button>
      );
    }
    return null;
  }

  AddCustomer(comments) {
    //  debugger;
    axios
      .post(api + `Admin/AddCustomer`, {
        customerId: 0,
        bodyType:
          this.state.vehicleRegistrationWithoutChassisNumber.bodyType._value,
        dLEmirate:
          this.state.vehicleRegistrationWithoutChassisNumber.dLEmirate._value,
        dLIssuedDate:
          this.state.vehicleRegistrationWithoutChassisNumber.dLIssuedDate
            ._values,
        dateOfRegistration:
          this.state.vehicleRegistrationWithoutChassisNumber.dateOfRegistration
            ._values,
        engineSize:
          this.state.vehicleRegistrationWithoutChassisNumber.engineSize._value,
        lOCRegion:
          this.state.vehicleRegistrationWithoutChassisNumber.dLEmirate._value,
        make: this.state.vehicleRegistrationWithoutChassisNumber.make._value,
        model: this.state.vehicleRegistrationWithoutChassisNumber.model._value,
        modelYear:
          this.state.vehicleRegistrationWithoutChassisNumber.modelYear._value,
        noClaimYear: "0",
        plateCode: this.state.vehicleDetails.plateCode._value,
        plateNumber: parseInt(this.state.vehicleDetails.plateNumber._value),
        policyStartDate:
          this.state.vehicleRegistrationWithoutChassisNumber.dateOfRegistration
            ._values,
        vehicleValue: round100(
          this.state.vehicleRegistrationWithoutChassisNumber.vehicleValue._value
        ),
        vehiclePrice: round100(
          this.state.vehicleRegistrationWithoutChassisNumber.vehicleTxtPrice
            ._value
        ),
        trimValue:
          this.state.vehicleRegistrationWithoutChassisNumber.trimValue._value,
        yourName: this.state.personalDetails.yourName._value,
        mobileNumber: this.state.personalDetails.mobileNumber._value,
        email: this.state.personalDetails.email._value,
        userID:
          this.props.location.search !== ""
            ? parseInt(this.props.location.search.substring(9))
            : parseInt(0),
        vehicleDetailsID: "0",
        quoteId: "0",
        stage: "Initial",
        comments: comments,
      })
      .then((response) => {
        //  debugger;
        if (response) {
          this.setState((prevState) => {
            let vehicleId = Object.assign({}, prevState.vehicleId);
            this.state.vehicleId = response.data;
            return { vehicleId: vehicleId };
          });
          this.AddAdvisor(response.data);
        }
      });
  }
  // roundup(v) {
  //    return (v >= 0 || -1) * Math.pow(10, 1 + Math.floor(Math.log10(Math.abs(v))));
  //}
  AddAdvisor(customerid) {
    axios
      .post(
        api +
          `User/GetPersonalAdvisorDetails?username=` +
          this.state.personalDetails.email._value
      )
      .then(function (response) {
        if (response.data !== null) {
          console.log(response.data);
          //Store advisor details
          axios.post(api + `Advisor/AddAdvisor`, {
            customerid: customerid,
            name: response.data.Name,
            mobileno: response.data.MobileNo,
            email: response.data.Email,
            isactive: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    // <ToastContainer />
    return (
      <div className="wrapper bg-light">
        <header className="pt-2 pb-2 mb-4 border-bottom bg-blue">
          <div className="container">
            <a
              href="/"
              className="d-flex align-items-center text-dark text-decoration-none"
            >
              <img alt="" src="img/bbc-1.png" width="250px" />
            </a>
          </div>
        </header>
        <div className="outer-wrap">
          <div className="container">
            {/* <div className="row">
              <div className="col-12">
                <h1>Car Insurance Form</h1>

                <div className="separator mb-5"></div>
              </div>
            </div> */}

            <div className="row">
              <div className="col-lg-6 col-xs-12  mb-4 ">
                <div className="promotional-content-left">
                  <h5>Welcome to bankbychoice.com</h5>
                  <h4>
                    Avail your Car Insurance in <span>60</span> seconds!
                  </h4>
                </div>

                <div className="outer-image-wrap">
                  <div className="svg-car">
                    <div className="bk-overlay">
                      <div className="box-left">
                        {this.state.collapse && (
                          <div className="default-content mar-b-20">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="ad_content">
                                  <div className="card-box">
                                    <div className="car-icon">
                                      <img
                                        alt=""
                                        src="/img/timer-car.svg"
                                      ></img>
                                    </div>
                                    <h3>
                                      Now you can
                                      <br />
                                      Buy your Car Insurance
                                      <br />
                                      <span className="minute">
                                        within a minute!
                                      </span>
                                    </h3>
                                  </div>
                                  <div className="card-box box-2">
                                    <h3>
                                      Enjoy upto <br />
                                      <span>30%</span>
                                      <br />
                                      discount on your <br /> car insurance
                                      premium
                                    </h3>
                                    <div className="car-icon">
                                      <img alt="" src="/img/happy.svg"></img>
                                    </div>
                                  </div>

                                  {/* <div className="image-section"><img alt="" src="/img/promotion-left-edited.svg" width="100%"></img></div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div
                          id="accordion"
                          className="after-next"
                          style={{ display: this.state.display }}
                        >
                          <div>
                            <button
                              className={
                                this.state.accordionShow
                                  ? "btn btn-link collapsed w-100 btn-1 "
                                  : "btn btn-link w-100 btn-1"
                              }
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              onClick={() => this.accordionShow()}
                              aria-expanded="false"
                              aria-controls="collapseOne"
                            >
                              <div className="head_left">
                                <h3>
                                  <span className="icon-car">
                                    <img alt="" src="img/car-new.svg" />
                                  </span>{" "}
                                  Car Details:
                                </h3>
                                <span>
                                  <i
                                    className={
                                      this.state.accordionShow
                                        ? "fas fa-chevron-up"
                                        : "fas fa-chevron-down"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </button>

                            <div
                              id="collapseOne"
                              className={this.state.accordionDisplay}
                              data-parent="#accordion"
                            >
                              <div className="p-2">
                                <div className="row mob-fix">
                                  <div className="col-lg-5">
                                    <div className="list-content">
                                      <ul className="car-details">
                                        <li
                                          onClick={() =>
                                            this.state
                                              .vehicleRegistrationWithoutChassisNumber
                                              .modelYear._value
                                              ? this.onClickStep1(0, false)
                                              : ""
                                          }
                                        >
                                          <strong>Car Model Year:</strong>
                                          <span
                                            style={
                                              this.state.color1 === true
                                                ? { color: "green" }
                                                : { color: "green" }
                                            }
                                          >
                                            {
                                              this.state
                                                .vehicleRegistrationWithoutChassisNumber
                                                .modelYear._value
                                            }
                                          </span>
                                          <span>
                                            <i className="fas fa-pen"></i>
                                          </span>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.state
                                              .vehicleRegistrationWithoutChassisNumber
                                              .make._value
                                              ? this.onClickStep1(1, false)
                                              : ""
                                          }
                                        >
                                          {" "}
                                          <strong>Car Make:</strong>
                                          <span
                                            style={
                                              this.state.color2 === true
                                                ? { color: "green" }
                                                : { color: "green" }
                                            }
                                          >
                                            {
                                              this.state
                                                .vehicleRegistrationWithoutChassisNumber
                                                .make._value
                                            }
                                          </span>{" "}
                                          <span>
                                            <i className="fas fa-pen"></i>
                                          </span>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.state
                                              .vehicleRegistrationWithoutChassisNumber
                                              .model._value
                                              ? this.onClickStep1(2, false)
                                              : ""
                                          }
                                        >
                                          {" "}
                                          <strong>Car Model :</strong>
                                          <span
                                            style={
                                              this.state.color3 === true
                                                ? { color: "green" }
                                                : { color: "green" }
                                            }
                                          >
                                            {
                                              this.state
                                                .vehicleRegistrationWithoutChassisNumber
                                                .model._value
                                            }
                                          </span>{" "}
                                          <span>
                                            <i className="fas fa-pen"></i>
                                          </span>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.state
                                              .vehicleRegistrationWithoutChassisNumber
                                              .trimValue._value
                                              ? this.onClickStep1(3, false)
                                              : ""
                                          }
                                        >
                                          {" "}
                                          <strong>Car Trim:</strong>
                                          <span
                                            style={
                                              this.state.color4 === true
                                                ? { color: "green" }
                                                : { color: "green" }
                                            }
                                          >
                                            {
                                              this.state
                                                .vehicleRegistrationWithoutChassisNumber
                                                .trimValue._value
                                            }
                                          </span>{" "}
                                          <span>
                                            <i className="fas fa-pen"></i>
                                          </span>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.state
                                              .vehicleRegistrationWithoutChassisNumber
                                              .bodyType._value
                                              ? this.onClickStep1(4, false)
                                              : ""
                                          }
                                        >
                                          {" "}
                                          <strong>Car Variant:</strong>
                                          <span
                                            style={
                                              this.state.color5 === true
                                                ? { color: "green" }
                                                : { color: "green" }
                                            }
                                          >
                                            {
                                              this.state
                                                .vehicleRegistrationWithoutChassisNumber
                                                .bodyType._value
                                            }
                                          </span>{" "}
                                          <span>
                                            <i className="fas fa-pen"></i>
                                          </span>
                                        </li>

                                        <li
                                          onClick={() =>
                                            this.state
                                              .vehicleRegistrationWithoutChassisNumber
                                              .engineSize._value
                                              ? this.onClickStep1(5, false)
                                              : ""
                                          }
                                        >
                                          <strong>Engine Size:</strong>
                                          <span
                                            style={
                                              this.state.color6 === true
                                                ? { color: "green" }
                                                : { color: "green" }
                                            }
                                          >
                                            {
                                              this.state
                                                .vehicleRegistrationWithoutChassisNumber
                                                .engineSize._value
                                            }
                                          </span>
                                          <span>
                                            <i className="fas fa-pen"></i>
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col-lg-7">
                                    <div className="list-content">
                                      <ul className="car-details">
                                        <li
                                          onClick={() =>
                                            this.state
                                              .vehicleRegistrationWithoutChassisNumber
                                              .dateOfRegistration._value
                                              ? this.onClickStep1(6, false)
                                              : ""
                                          }
                                        >
                                          <strong>Registration Date:</strong>
                                          <span
                                            style={
                                              this.state.color7 === true
                                                ? { color: "green" }
                                                : { color: "green" }
                                            }
                                          >
                                            {
                                              this.state
                                                .vehicleRegistrationWithoutChassisNumber
                                                .dateOfRegistration._values
                                            }
                                          </span>
                                          <span>
                                            <i className="fas fa-pen"></i>
                                          </span>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.state
                                              .vehicleRegistrationWithoutChassisNumber
                                              .dLIssuedDate._value
                                              ? this.onClickStep1(7, false)
                                              : ""
                                          }
                                        >
                                          <strong>
                                            UAE Driving License Issued Date:
                                          </strong>
                                          <span
                                            style={
                                              this.state.color8 === true
                                                ? { color: "green" }
                                                : { color: "green" }
                                            }
                                          >
                                            {
                                              this.state
                                                .vehicleRegistrationWithoutChassisNumber
                                                .dLIssuedDate._values
                                            }
                                          </span>
                                          <span>
                                            <i className="fas fa-pen"></i>
                                          </span>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.state
                                              .vehicleRegistrationWithoutChassisNumber
                                              .dLEmirate._value
                                              ? this.onClickStep1(8, false)
                                              : ""
                                          }
                                        >
                                          <strong>City:</strong>
                                          <span
                                            style={
                                              this.state.color9 === true
                                                ? { color: "green" }
                                                : { color: "green" }
                                            }
                                          >
                                            {
                                              this.state
                                                .vehicleRegistrationWithoutChassisNumber
                                                .dLEmirate._value
                                            }
                                          </span>
                                          <span>
                                            <i className="fas fa-pen"></i>
                                          </span>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.state
                                              .vehicleRegistrationWithoutChassisNumber
                                              .vehicleValue._value
                                              ? this.onClickStep1(9, false)
                                              : ""
                                          }
                                        >
                                          <strong>
                                            Vehicle Purchase Price:
                                          </strong>
                                          <span
                                            style={
                                              this.state.color10 === false
                                                ? { color: "green" }
                                                : { color: "green" }
                                            }
                                          >
                                            {round100(
                                              this.state
                                                .vehicleRegistrationWithoutChassisNumber
                                                .vehiclePrice._value
                                            )}
                                          </span>
                                          <span>
                                            <i className="fas fa-pen"></i>
                                          </span>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.state
                                              .vehicleRegistrationWithoutChassisNumber
                                              .vehicleValue._value
                                              ? this.onClickStep1(9, false)
                                              : ""
                                          }
                                        >
                                          <strong>
                                            CarValue to InsureNow:
                                          </strong>
                                          <span
                                            style={
                                              this.state.color10 === false
                                                ? { color: "green" }
                                                : { color: "green" }
                                            }
                                          >
                                            {round100(
                                              this.state
                                                .vehicleRegistrationWithoutChassisNumber
                                                .vehicleValue._value
                                            )}
                                          </span>
                                          <span>
                                            <i className="fas fa-pen"></i>
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              className={
                                this.state.accordionShow2
                                  ? "btn btn-link collapsed w-100 btn-1 "
                                  : "btn btn-link w-100 btn-1"
                              }
                              data-toggle="collapse"
                              onClick={() => this.accordionShow2()}
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              <div className="head_left">
                                <h3>
                                  <span className="icon-car">
                                    <img alt="" src="img/bussiness-man.svg" />
                                  </span>{" "}
                                  Personal Details:
                                </h3>
                                <span>
                                  <i
                                    className={
                                      this.state.accordionShow2
                                        ? "fas fa-chevron-up"
                                        : "fas fa-chevron-down"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </button>
                            <div
                              id="collapseTwo"
                              className={this.state.accordionDisplay2}
                              data-parent="#accordion"
                            >
                              <div className="p-2">
                                <div className="row">
                                  <div className="col-lg-5">
                                    <div className="list-content">
                                      <ul className="car-details">
                                        <li
                                          onClick={() =>
                                            this.state.vehicleDetails.plateCode
                                              ._value
                                              ? this.onClickStep2(0, false)
                                              : ""
                                          }
                                        >
                                          <strong>Plate Code :</strong>
                                          <span
                                            style={
                                              this.state.color21 === false
                                                ? { color: "green" }
                                                : { color: "green" }
                                            }
                                          >
                                            {
                                              this.state.vehicleDetails
                                                .plateCode._value
                                            }
                                          </span>
                                          <span>
                                            <i className="fas fa-pen"></i>
                                          </span>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.state.vehicleDetails
                                              .plateNumber._value
                                              ? this.onClickStep2(0, false)
                                              : ""
                                          }
                                        >
                                          {" "}
                                          <strong>Plate Number :</strong>
                                          <span
                                            style={
                                              this.state.color22 === false
                                                ? { color: "green" }
                                                : { color: "green" }
                                            }
                                          >
                                            {
                                              this.state.vehicleDetails
                                                .plateNumber._value
                                            }
                                          </span>{" "}
                                          <span>
                                            <i className="fas fa-pen"></i>
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col-lg-7">
                                    <div className="list-content">
                                      <ul className="car-details">
                                        <li
                                          onClick={() =>
                                            this.onClickStep2(1, false)
                                          }
                                        >
                                          <strong>Your Name :</strong>
                                          <span
                                            style={
                                              this.state.color31 === false
                                                ? { color: "green" }
                                                : { color: "green" }
                                            }
                                          >
                                            {
                                              this.state.personalDetails
                                                .yourName._value
                                            }
                                          </span>
                                          <span>
                                            <i className="fas fa-pen"></i>
                                          </span>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.onClickStep2(1, false)
                                          }
                                        >
                                          {" "}
                                          <strong>Mobile No:</strong>
                                          <span
                                            style={
                                              this.state.color32 === false
                                                ? { color: "green" }
                                                : { color: "green" }
                                            }
                                          >
                                            {
                                              this.state.personalDetails
                                                .mobileNumber._value
                                            }
                                          </span>{" "}
                                          <span>
                                            <i className="fas fa-pen"></i>
                                          </span>
                                        </li>

                                        <li
                                          onClick={() =>
                                            this.onClickStep2(1, false)
                                          }
                                        >
                                          {" "}
                                          <strong>Email ID:</strong>
                                          <span
                                            style={
                                              this.state.color33 === false
                                                ? { color: "green" }
                                                : { color: "green" }
                                            }
                                          >
                                            {
                                              this.state.personalDetails.email
                                                ._value
                                            }
                                          </span>{" "}
                                          <span>
                                            <i className="fas fa-pen"></i>
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-xs-12  mb-4">
                <div className="card mb-4 no-bakground-light-border">
                  <div id="smartWizardValidation">
                    {/* <div className="city-bk"></div> */}
                    <div className="road-bk">
                      <img
                        alt=""
                        src="/img/suv-with-smoke.svg"
                        style={{ left: `${this.state.carLeftStyle}%` }}
                      />
                    </div>
                    <form onSubmit={(e) => this.onSubmit()}>
                      <div>
                        <Step1
                          currentStep={this.state.currentStep}
                          time={this.state.time}
                          loader={this.state.loader}
                          sliderDetails={this.state.sliderDetails}
                          updateCount={this.state.updateCount}
                          handleClickMake={this.handleClickMake}
                          handleClickYear={this.handleClickYear}
                          handleClickTrim={this.handleClickTrim}
                          handleClickModelMake={this.handleClickModelMake}
                          handleClickBodyType={this.handleClickBodyType}
                          handleRegistrationDate={this.handleRegistrationDate}
                          handleDrivingLicenseIssuedDate={
                            this.handleDrivingLicenseIssuedDate
                          }
                          handleClickEngineSize={this.handleClickEngineSize}
                          handleClickEmirates={this.handleClickEmirates}
                          onChange={
                            this
                              .handleVehicleRegistrationWithoutChassisNumberDetailChange
                          }
                          onVehicleTxtPriceChange={this.onVehicleTxtPriceChange}
                          vehicleRegistrationWithoutChassisNumber={
                            this.state.vehicleRegistrationWithoutChassisNumber
                          }
                          makeMaster={this.state.makeMaster}
                          modelMake={this.state.modelMake}
                          trimMaster={this.state.trimMaster}
                          yearMaster={this.state.yearMaster}
                          minimumDate={this.state.minimumDate}
                          maximumDate={this.state.maximumDate}
                          engineSizeMaster={this.state.engineSizeMaster}
                          emiratesMaster={this.state.emiratesMaster}
                          bodyTypeMaster={this.state.bodyTypeMaster}
                          slideIndex={this.state.slideIndex}
                          setState={this.setState}
                          activeListMake={this.state.activeListMake}
                          activeListTrim={this.state.activeListTrim}
                          activeListModelMake={this.state.activeListModelMake}
                          activeListBodyType={this.state.activeListBodyType}
                          activeListEngineSize={this.state.activeListEngineSize}
                          activeListYear={this.state.activeListYear}
                          activeListEmirates={this.state.activeListEmirates}
                          selectedRegistrationDate={
                            this.state.selectedRegistrationDate
                          }
                          submitVehicleRegistrationWithoutChassisNumberDetails={
                            this.state
                              .submitVehicleRegistrationWithoutChassisNumberDetails
                          }
                          filter={this.state.filter}
                          ref={this.slide1}
                          responseMsg={this.state.responseMsg}
                          setSmShow={this.setSmShow}
                          smShow={this.state.smShow}
                        />
                        <Step2
                          currentStep={this.state.currentStep}
                          submitVehicleDetails={this.state.submitVehicleDetails}
                          vehicleDetails={this.state.vehicleDetails}
                          onChange={this.handleVehicleDetailChange}
                          personalDetails={this.state.personalDetails}
                          onChange2={this.handlePersonalDetailChange}
                          submitPersonalDetails={
                            this.state.submitPersonalDetails
                          }
                          loader={this.state.loader}
                          sliderDetails={this.state.sliderDetails}
                          updateCount2={this.state.updateCount2}
                          slideIndex2={this.state.slideIndex2}
                          setState={this.setState}
                          ref={this.slide2}
                          responseMsg={this.state.responseMsg}
                          setSmShow={this.setSmShow}
                          smShow={this.state.smShow}
                          closePopup={this.state.closePopup}
                        />
                        <Step3
                          currentStep={this.state.currentStep}
                          loader={this.state.loader}
                          responseMsg={this.state.responseMsg}
                          setSmShow={this.setSmShow}
                          smShow={this.state.smShow}
                        ></Step3>

                        <div className="container custom_next">
                          {this.previousButton()}
                          {this.nextButton()}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="page-footer">
            <div className="footer">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-sm-12">
                    <p className="">
                      Copyright {new Date().getFullYear().toString()} |
                      bankbychoice.com – All rights reserved
                    </p>
                  </div>
                  <div className="col-lg-4 col-sm-12">
                    <p className=" text-center">
                      Insurance is provided by Gulf Oasis Insurance Brokers LLC
                      Authored by UAE Insurance Authority
                    </p>
                  </div>
                  <div className="col-lg-4 col-sm-12">
                    <ul className="breadcrumb pt-0 pr-0 float-right">
                      <li className="breadcrumb-item ">
                        <a
                          target="_blank"
                          href="https://www.bankbychoice.com/terms-conditions/"
                          className="btn-link"
                        >
                          Terms of Use
                        </a>
                      </li>
                      <li className="breadcrumb-item ">
                        <a
                          target="_blank"
                          href="https://www.bankbychoice.com/privacy-policy/"
                          className="btn-link"
                        >
                          Privacy Policy
                        </a>
                      </li>
                      {/* <li className="breadcrumb-item mb-0">
                        <a href="#" className="btn-link">Docs</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

function SampleNextArrow1(props) {
  const { style, onClick } = props;
  const char = "→";
  // console.log('onClick', props)
  return (
    <span
      className="nextArrow"
      style={{
        ...style,
        display:
          props.currentSlide === 0 &&
          props.vehicleRegistrationWithoutChassisNumber.modelYear._value
            ? "block"
            : props.currentSlide === 1 &&
              props.vehicleRegistrationWithoutChassisNumber.make._value
            ? "block"
            : props.currentSlide === 2 &&
              props.vehicleRegistrationWithoutChassisNumber.model._value
            ? "block"
            : props.currentSlide === 3 &&
              props.vehicleRegistrationWithoutChassisNumber.trimValue._value
            ? "block"
            : props.currentSlide === 4 &&
              props.vehicleRegistrationWithoutChassisNumber.bodyType._value
            ? "block"
            : props.currentSlide === 5 &&
              props.vehicleRegistrationWithoutChassisNumber.engineSize._value
            ? "block"
            : props.currentSlide === 6 &&
              props.vehicleRegistrationWithoutChassisNumber.dateOfRegistration
                ._value
            ? "block"
            : props.currentSlide === 7 &&
              props.vehicleRegistrationWithoutChassisNumber.dLIssuedDate._value
            ? "block"
            : props.currentSlide === 8 &&
              props.vehicleRegistrationWithoutChassisNumber.dLEmirate._value
            ? "block"
            : props.currentSlide === 9 &&
              props.vehicleRegistrationWithoutChassisNumber.vehicleValue._value
            ? "none"
            : "none",

        opacity: "0.9",
        color: "#b7d43a",
        fontSize: "42px",
        // !props.vehicleRegistrationWithoutChassisNumber.make._value ? 'none' :'block'
        //  props.vehicleRegistrationWithoutChassisNumber.modelYear._value?  'block': !props.vehicleRegistrationWithoutChassisNumber.modelMake._value ? 'none' :
        //  props.vehicleRegistrationWithoutChassisNumber.modelYear._value?  'block': !props.vehicleRegistrationWithoutChassisNumber.trimValue._value ? 'block' :
        //  props.vehicleRegistrationWithoutChassisNumber.modelYear._value?  'block': !props.vehicleRegistrationWithoutChassisNumber.bodyType._value ? 'block' :
        //  props.vehicleRegistrationWithoutChassisNumber.modelYear._value?  'block': !props.vehicleRegistrationWithoutChassisNumber.engineSize._value ? 'block' :
        //  props.vehicleRegistrationWithoutChassisNumber.modelYear._value?  'block': !props.vehicleRegistrationWithoutChassisNumber.dateOfRegistration._value ? 'block' :
        //  props.vehicleRegistrationWithoutChassisNumber.modelYear._value?  'block': !props.vehicleRegistrationWithoutChassisNumber.dLIssuedDate._value ? 'block' :
        //  props.vehicleRegistrationWithoutChassisNumber.modelYear._value?  'block': !props.vehicleRegistrationWithoutChassisNumber.dLEmirate._value ? 'block' :
        //  props.vehicleRegistrationWithoutChassisNumber.modelYear._value?  'block': !props.vehicleRegistrationWithoutChassisNumber.vehicleValue._value:''
      }}
      onClick={onClick}
    >
      {char}
    </span>
  );
}
function SampleNextArrow2(props) {
  const { style, onClick } = props;
  const char = "→";
  // //console.log('onClick', props)
  return (
    <span
      className="nextArrow"
      style={{
        ...style,

        display:
          props.currentSlide === 0 &&
          props.vehicleDetails.plateCode._value &&
          props.vehicleDetails.plateNumber._value
            ? "block"
            : props.currentSlide === 1
            ? "none"
            : "none",

        opacity: "0.9",
        color: "#b7d43a",
        fontSize: "42px",
      }}
      onClick={onClick}
    >
      {char}
    </span>
  );
}
function SamplePrevArrow(props) {
  const { style, onClick } = props;

  const char = "←";
  return (
    <span
      className="prevArrow"
      style={{
        ...style,
        display: props.currentSlide === 0 ? "none" : "block",
        opacity: "0.9",
        fontSize: "35px",
      }}
      onClick={onClick}
    >
      {char}
    </span>
  );
}
const Step1 = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    gotoSlide1(a, b) {
      props.sliderDetails.Sliders._value.slickGoTo(a, b);
    },
  }));
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: false,
    horizontal: false,
    horizontalSwiping: false,
    draggable: false,
    afterChange: () =>
      props.setState((state, props) => ({
        updateCount: state.updateCount + 1,
      })),
    beforeChange: (current, next) => props.setState({ slideIndex: next }),
  };
  const [filterYear, setFilterYear] = useState("");
  const [filterMake, setFilterMake] = useState("");
  const [filterModelMake, setFilterModelMake] = useState("");
  const [filterTrim, setFilterTrim] = useState("");
  const [filterBodyType, setFilterBodyType] = useState("");
  const [filterEngineSize, setFilterEngineSize] = useState("");
  const [filterEmirates, setFilterEmirates] = useState("");

  if (props.currentStep !== 1) {
    return null;
  }
  return (
    <React.Fragment>
      <div>
        <div className="card-body pt-0 pb-0">
          <div id="step-0">
            <Sliders
              className="vertical-center Sliders"
              nextArrow={
                <SampleNextArrow1
                  vehicleRegistrationWithoutChassisNumber={
                    props.vehicleRegistrationWithoutChassisNumber
                  }
                />
              }
              prevArrow={<SamplePrevArrow />}
              ref={(Sliders) => (props.sliderDetails.Sliders._value = Sliders)}
              {...settings}
            >
              <div className="field-section">
                <div className="heading-tag">
                  <h1>Car Model Year</h1>
                </div>
                <div className="filter_search">
                  <input
                    id="filterYear"
                    name="filterYear"
                    type="text"
                    placeholder="Search Car Model Year..."
                    value={filterYear}
                    onChange={(event) => setFilterYear(event.target.value)}
                  />
                  <span className="icon fas fa-search fa-lg"></span>
                </div>
                <ul>
                  {props.yearMaster
                    .filter(
                      (f) =>
                        f.year.toString().toUpperCase().includes(filterYear) ||
                        filterYear === ""
                    )
                    .map((year) => (
                      <li
                        onClick={() =>
                          props.handleClickYear(year.id, year.year)
                        }
                        className={
                          year.year === props.activeListYear ? " selected" : ""
                        }
                        key={year.id}
                        data-value={year.year}
                      >
                        <div className="nat-name">
                          {" "}
                          <span>{year.year}</span>
                        </div>
                      </li>
                    ))}
                </ul>
                {/* <ul>
                 {props.yearMaster.map(list => {
                    return (
                      <li
                        onClick={() => props.handleClickYear(list.id, list.year)}
                        className={
                          (list.id === props.activeListYear ? " selected" : "")
                        }
                      ><div className="nat-name"><span>
                        {list.year}
                      </span></div>  </li>

                    );
                  })}
                </ul> */}
              </div>
              <div className="field-section">
                <div className="heading-tag">
                  <h1>Car Make</h1>
                </div>

                <div className="filter_search">
                  <input
                    id="filterMake"
                    name="filterMake"
                    type="text"
                    placeholder="Search for Car make..."
                    onChange={(event) =>
                      setFilterMake(event.target.value.toLowerCase())
                    }
                  />
                  <span className="icon fas fa-search fa-lg"></span>
                </div>
                <div className="input-selection">
                  <div>
                    <ul>
                      {props.makeMaster
                        .filter(
                          (f) =>
                            f.make
                              .toString()
                              .toLowerCase()
                              .includes(filterMake) +
                              f.make
                                .toString()
                                .charAt(1)
                                .toLowerCase()
                                .includes(filterMake) || filterMake === ""
                        )
                        .map((list) => {
                          return (
                            <li
                              onClick={() =>
                                props.handleClickMake(list.id, list.make)
                              }
                              className={
                                list.make === props.activeListMake
                                  ? " selected"
                                  : ""
                              }
                            >
                              <div className="nat-name">
                                <span>{list.make}</span>
                              </div>{" "}
                            </li>
                          );
                          // );
                        })}{" "}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="field-section">
                <div className="heading-tag">
                  <h1>Car Model</h1>
                </div>
                <div className="filter_search">
                  <input
                    id="filterModelMake"
                    name="filterModelMake"
                    type="text"
                    placeholder="Search for Car Model..."
                    onChange={(event) =>
                      setFilterModelMake(event.target.value.toLowerCase())
                    }
                  />
                  <span className="icon fas fa-search fa-lg"></span>
                </div>

                <ul>
                  {props.modelMake
                    .filter(
                      (f) =>
                        f.model
                          .toString()
                          .toLowerCase()
                          .includes(filterModelMake) || filterModelMake === ""
                    )
                    .map((list) => {
                      return (
                        <li
                          onClick={() =>
                            props.handleClickModelMake(list.id, list.model)
                          }
                          data-value={list.model}
                          className={
                            list.model === props.activeListModelMake
                              ? " selected"
                              : ""
                          }
                        >
                          <div className="nat-name">
                            <span>{list.model}</span>
                          </div>{" "}
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className="field-section">
                <div className="heading-tag">
                  <h1>Car Trim</h1>
                </div>
                <div className="filter_search">
                  <input
                    id="filterTrim"
                    name="filterTrim"
                    type="text"
                    placeholder="Search for Car trim..."
                    onChange={(event) =>
                      setFilterTrim(event.target.value.toLowerCase())
                    }
                  />
                  <span className="icon fas fa-search fa-lg"></span>
                </div>
                <ul>
                  {props.trimMaster
                    .filter(
                      (f) =>
                        f.trim.toString().toLowerCase().includes(filterTrim) ||
                        filterTrim === ""
                    )
                    .map((list) => {
                      return (
                        <li
                          onClick={() =>
                            props.handleClickTrim(list.id, list.trim)
                          }
                          data-value={list.trim}
                          className={
                            list.trim === props.activeListTrim
                              ? " selected"
                              : ""
                          }
                        >
                          <div className="nat-name">
                            <span>{list.trim}</span>
                          </div>{" "}
                        </li>
                      );
                    })}
                </ul>
              </div>

              <div className="field-section">
                <div className="heading-tag">
                  <h1>Car Variant</h1>
                </div>
                <div className="filter_search">
                  <input
                    id="filterBodyType"
                    name="filterBodyType"
                    placeholder="Search for Car Variant..."
                    type="text"
                    onChange={(event) =>
                      setFilterBodyType(event.target.value.toLowerCase())
                    }
                  />
                  <span className="icon fas fa-search fa-lg"></span>
                </div>
                <ul>
                  {" "}
                  {props.bodyTypeMaster
                    .filter(
                      (f) =>
                        f.bodyType
                          .toString()
                          .toLowerCase()
                          .includes(filterBodyType) || filterBodyType === ""
                    )
                    .map((list) => {
                      return (
                        <li
                          onClick={() =>
                            props.handleClickBodyType(list.id, list.bodyType)
                          }
                          className={
                            list.bodyType === props.activeListBodyType
                              ? " selected"
                              : ""
                          }
                        >
                          <div className="nat-name">
                            <span>{list.bodyType}</span>
                          </div>{" "}
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className="field-section">
                <div className="heading-tag">
                  <h1>Engine Size</h1>
                </div>
                <div className="filter_search">
                  <input
                    id="filterEngineSize"
                    name="filterEngineSize"
                    placeholder="Search for Car Engine Size..."
                    type="text"
                    onChange={(event) =>
                      setFilterEngineSize(event.target.value.toLowerCase())
                    }
                  />
                  <span className="icon fas fa-search fa-lg"></span>
                </div>
                <ul>
                  {props.engineSizeMaster
                    .filter(
                      (f) =>
                        f.engineSize
                          .toString()
                          .toLowerCase()
                          .includes(filterEngineSize) || filterEngineSize === ""
                    )
                    .map((list) => {
                      return (
                        <li
                          onClick={() =>
                            props.handleClickEngineSize(
                              list.id,
                              list.engineSize
                            )
                          }
                          className={
                            list.engineSize === props.activeListEngineSize
                              ? " selected"
                              : ""
                          }
                        >
                          <div className="nat-name">
                            <span>{list.engineSize}</span>
                          </div>{" "}
                        </li>
                      );
                    })}
                </ul>
              </div>

              <div className="Calender-field-section">
                <div className="heading-tag">
                  <h1>First Registration Date </h1>
                </div>
                <Calendar
                  className={"Calendars__yearText"}
                  value={
                    props.vehicleRegistrationWithoutChassisNumber
                      .dateOfRegistration._value
                  }
                  onChange={props.handleRegistrationDate}
                  maximumDate={utils().getToday()}
                  shouldHighlightWeekends
                />
              </div>
              <div className="Calender-field-section">
                <div className="heading-tag">
                  <h1>UAE Driving License Issue Date</h1>
                </div>
                <Calendar
                  value={
                    props.vehicleRegistrationWithoutChassisNumber.dLIssuedDate
                      ._value
                  }
                  onChange={props.handleDrivingLicenseIssuedDate}
                  maximumDate={utils().getToday()}
                  shouldHighlightWeekends
                />
              </div>

              {/* <div className="field-section">
              </div> */}
              <div className="field-section">
                <div className="heading-tag">
                  <h1>Is your vehicle GCC Specification</h1>
                  &nbsp;&nbsp;{" "}
                  <input
                    type="radio"
                    value="true"
                    defaultChecked
                    name="gcc"
                    id="gcc"
                    onChange={props.onChange}
                  />{" "}
                  Yes &nbsp;&nbsp;
                  <input
                    type="radio"
                    value="false"
                    name="gcc"
                    id="gcc"
                    onChange={props.onChange}
                  />
                  No
                </div>

                <div className="heading-tag">
                  <h1>City</h1>
                </div>
                <div className="filter_search">
                  <input
                    id="filterEmirates"
                    name="filterEmirates"
                    type="text"
                    placeholder="Search for Emirates..."
                    onChange={(event) =>
                      setFilterEmirates(event.target.value.toLowerCase())
                    }
                  />
                  <span className="icon fas fa-search fa-lg"></span>
                </div>
                <ul>
                  {props.emiratesMaster
                    .filter(
                      (f) =>
                        f.name
                          .toString()
                          .toLowerCase()
                          .includes(filterEmirates) || filterEmirates === ""
                    )
                    .map((list) => {
                      return (
                        <li
                          onClick={() =>
                            props.handleClickEmirates(list.id, list.name)
                          }
                          className={
                            list.name === props.activeListEmirates
                              ? " selected"
                              : ""
                          }
                        >
                          <div className="nat-name">
                            <span>{list.name}</span>
                          </div>{" "}
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className="field-section">
                <div className="heading-tag">
                  <h1>
                    Vehicle Purchase Price:
                    <span>
                      {" "}
                      AED &nbsp;
                      {props.vehicleRegistrationWithoutChassisNumber
                        ? props.vehicleRegistrationWithoutChassisNumber
                            .vehiclePrice._value
                        : ""}
                    </span>
                  </h1>
                </div>
                <br />
                <div className="container">
                  <div className="row">
                    <div className="col-md">
                      <div
                        className="form-group has-float-label"
                        style={{ paddingLeft: "20px", paddingRight: "25px" }}
                      >
                        <div className="form-group col-lg-12">
                          <div className="icon-with-form">
                            <span className="icon fas fa-money-check-alt fa-lg"></span>
                            <span className="amount-aed">
                              <h5>AED</h5>
                            </span>
                            <input
                              type="text"
                              autoComplete="off"
                              name="VehiclePurchasePrice"
                              className="form-control"
                              maxLength="6"
                              placeholder="Enter Vehicle Purchase Price"
                              title="Vehicle Purchase Price"
                              onChange={props.onVehicleTxtPriceChange}
                              value={round100(
                                props.vehicleRegistrationWithoutChassisNumber
                                  .vehicleTxtPrice._value
                              )}
                            />
                          </div>
                        </div>
                        <br />
                        <br />
                        <InputRange
                          background="#b0d039"
                          step={500}
                          maxValue={1000000}
                          minValue={20000}
                          value={round100(
                            props.vehicleRegistrationWithoutChassisNumber
                              .vehiclePrice._value
                          )}
                          // onChange={value => props.setState({ value })} />
                          onChange={(value) => {
                            let vehicleRegistrationWithoutChassisNumberVehicleValue =
                              Object.assign(
                                {},
                                props.vehicleRegistrationWithoutChassisNumber
                              );

                            vehicleRegistrationWithoutChassisNumberVehicleValue[
                              "vehiclePrice"
                            ]._value = value;
                            vehicleRegistrationWithoutChassisNumberVehicleValue[
                              "vehicleTxtPrice"
                            ]._value = value.toString();
                            let currentYear = new Date();
                            let years =
                              currentYear.getFullYear() -
                              vehicleRegistrationWithoutChassisNumberVehicleValue[
                                "modelYear"
                              ]._value;
                            let vehicleActualPrice =
                              vehicleRegistrationWithoutChassisNumberVehicleValue[
                                "vehiclePrice"
                              ]._value;

                            for (let i = 0; i < years; i++) {
                              vehicleActualPrice =
                                vehicleActualPrice -
                                (vehicleActualPrice * 15) / 100;
                            }
                            vehicleActualPrice =
                              Number(vehicleActualPrice).toFixed(0);
                            vehicleRegistrationWithoutChassisNumberVehicleValue[
                              "vehicleValue"
                            ]._value = round100(vehicleActualPrice);
                            let vehicleMinValue =
                              vehicleActualPrice -
                              (vehicleActualPrice * 5) / 100;
                            vehicleMinValue = round100(
                              Number(vehicleMinValue).toFixed(0)
                            );
                            vehicleRegistrationWithoutChassisNumberVehicleValue[
                              "vehicleMinValue"
                            ]._value = Number(vehicleMinValue);
                            let maxPrice = (vehicleActualPrice * 5) / 100;
                            let vehicleMaxValue =
                              Number(vehicleActualPrice) + Number(maxPrice);
                            vehicleMaxValue =
                              Number(vehicleMaxValue).toFixed(0);
                            vehicleRegistrationWithoutChassisNumberVehicleValue[
                              "vehicleMaxValue"
                            ]._value = round100(Number(vehicleMaxValue));
                            props.setState({
                              vehicleRegistrationWithoutChassisNumber:
                                vehicleRegistrationWithoutChassisNumberVehicleValue,
                            });
                          }}
                        />

                        <br />
                        <br />
                        <br />
                        <div className="form-group has-float-label">
                          <span className="content-custom-car">
                            <h3>
                              Car Value to Insure now:
                              <span>
                                {" "}
                                AED &nbsp;
                                {props.vehicleRegistrationWithoutChassisNumber
                                  ? round100(
                                      props
                                        .vehicleRegistrationWithoutChassisNumber
                                        .vehicleValue._value
                                    )
                                  : ""}
                              </span>
                            </h3>{" "}
                          </span>
                          <br />
                          <br />
                          <br />
                          <InputRange
                            background="#b0d039"
                            disabled={
                              props.vehicleRegistrationWithoutChassisNumber
                                .vehiclePrice._value !== 0
                                ? false
                                : true
                            }
                            step={100}
                            maxValue={round100(
                              props.vehicleRegistrationWithoutChassisNumber
                                .vehicleMaxValue._value
                            )}
                            minValue={round100(
                              props.vehicleRegistrationWithoutChassisNumber
                                .vehicleMinValue._value
                            )}
                            value={round100(
                              props.vehicleRegistrationWithoutChassisNumber
                                .vehicleValue._value
                            )}
                            // onChange={value => props.setState({ value })} />
                            onChange={(value) => {
                              let vehicleRegistrationWithoutChassisNumberVehicleValue =
                                Object.assign(
                                  {},
                                  props.vehicleRegistrationWithoutChassisNumber
                                );

                              vehicleRegistrationWithoutChassisNumberVehicleValue[
                                "vehicleValue"
                              ]._value = value;
                              props.setState({
                                vehicleRegistrationWithoutChassisNumber:
                                  vehicleRegistrationWithoutChassisNumberVehicleValue,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Sliders>
          </div>
        </div>

        <div>
          <Modal
            fade={false}
            size="sm"
            centered
            isOpen={props.smShow}
            toggle={() => props.setSmShow()}
          >
            <ModalBody>
              <div>
                {" "}
                <Label>
                  {props.responseMsg !== ""
                    ? props.responseMsg
                    : "Something Went Wrong"}
                </Label>
              </div>
            </ModalBody>{" "}
            <ModalFooter>
              <Button color="secondary" onClick={() => props.setSmShow()}>
                Ok
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
});

const Step2 = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    gotoSlide2(a, b) {
      props.sliderDetails.slider2._value.slickGoTo(a, b);
    },
  }));
  const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: false,
    horizontal: false,
    horizontalSwiping: false,
    draggable: false,
    afterChange: () =>
      props.setState((state, props) => ({
        updateCount2: state.updateCount2 + 1,
      })),
    beforeChange: (current, next) => props.setState({ slideIndex2: next }),
  };
  if (props.currentStep !== 2) {
    return null;
  }
  return (
    <React.Fragment>
      {props.loader && <div className="loading" />}
      {/* {!props.loader && */}
      <div className={"close-popup " + props.closePopup}></div>
      <div className="card-body pt-0 pb-0">
        <div id="step-1">
          <Sliders
            className="vertical-center Sliders"
            nextArrow={
              <SampleNextArrow2 vehicleDetails={props.vehicleDetails} />
            }
            prevArrow={<SamplePrevArrow />}
            ref={(Sliders) => (props.sliderDetails.slider2._value = Sliders)}
            {...settings2}
          >
            <div className="field-section height-correrction">
              <div className="heading-tag">
                <h1>Plate Code &amp; Number</h1>
              </div>

              <div className="row">
                <div className="form-group col-lg-6">
                  <div className="icon-with-form">
                    <span className="icon fas fa-car fa-lg"></span>
                    <input
                      type="text"
                      autoComplete="off"
                      name="plateCode"
                      className="form-control"
                      placeholder="Enter Plate Code"
                      title="Plate Code"
                      maxLength="2"
                      pattern="[a-zA-Z0-9]+"
                      onChange={props.onChange}
                      value={props.vehicleDetails.plateCode._value}
                    />
                  </div>
                  {(props.submitVehicleDetails ||
                    props.vehicleDetails.plateCode.touched) &&
                  !props.vehicleDetails.plateCode._value ? (
                    <span className="text-danger">
                      {props.vehicleDetails.plateCode.errorMsg}
                    </span>
                  ) : (
                    <span className="text-danger">
                      {props.vehicleDetails.plateCode.errorMessage}
                    </span>
                  )}

                  {/* <span>Plate Code</span> */}
                </div>
                <div className="form-group col-lg-6">
                  <div className="icon-with-form">
                    <span className="icon fas fa-id-card fa-lg"></span>
                    <input
                      type="text"
                      autoComplete="off"
                      name="plateNumber"
                      className="form-control"
                      placeholder="Plate Number"
                      title="Plate Number"
                      maxLength="5"
                      pattern="[0-9]+"
                      onChange={props.onChange}
                      value={props.vehicleDetails.plateNumber._value}
                    />
                    {/* <span>Plate Number</span> */}
                  </div>{" "}
                  {(props.submitVehicleDetails ||
                    props.vehicleDetails.plateNumber.touched) &&
                  !props.vehicleDetails.plateNumber._value ? (
                    <span className="text-danger">
                      {props.vehicleDetails.plateNumber.errorMsg}
                    </span>
                  ) : (
                    <span className="text-danger">
                      {props.vehicleDetails.plateNumber.errorMessage}
                    </span>
                  )}
                </div>
              </div>

              <div className="heading-tag mt-10">
                <h1>
                  We are almost done, we just need some more details to show the
                  best quote for you!
                </h1>
              </div>

              <div className="row">
                <div className="form-group col-lg-6">
                  <div className="icon-with-form">
                    <span className="icon fa fa-user fa-lg"></span>
                    <input
                      type="text"
                      autoComplete="off"
                      name="yourName"
                      className="form-control"
                      placeholder="Your Name"
                      title="Your Name"
                      required
                      pattern="[a-zA-Z\s]{3,}"
                      onChange={props.onChange2}
                      value={props.personalDetails.yourName._value}
                    />
                  </div>{" "}
                  {(props.submitPersonalDetails ||
                    props.personalDetails.yourName.touched) &&
                  !props.personalDetails.yourName._value ? (
                    <span className="text-danger">
                      {props.personalDetails.yourName.errorMsg}
                    </span>
                  ) : (
                    <span className="text-danger">
                      {props.personalDetails.yourName.errorMessage}
                    </span>
                  )}
                </div>
                <div className="form-group col-lg-6">
                  <div className="icon-with-form">
                    <span className="icon fas fa-mobile fa-lg"></span>

                    {/* < input type="text" autoComplete="off" */}
                    <NumberFormat
                      format="0#########"
                      mask=""
                      name="mobileNumber"
                      className="form-control"
                      placeholder="Mobile Number (502020333)"
                      maxLength="10"
                      title="Mobile Number"
                      pattern="[0-9]{10}"
                      onChange={props.onChange2}
                      value={props.personalDetails.mobileNumber._value}
                    />
                    {/* <span>Mobile Number</span> */}
                  </div>{" "}
                  {(props.submitPersonalDetails ||
                    props.personalDetails.mobileNumber.touched) &&
                  !props.personalDetails.mobileNumber._value ? (
                    <span className="text-danger">
                      {props.personalDetails.mobileNumber.errorMsg}
                    </span>
                  ) : (
                    <span className="text-danger">
                      {props.personalDetails.mobileNumber.errorMessage}
                    </span>
                  )}
                </div>

                <div className="form-group col-lg-6">
                  <div className="icon-with-form">
                    <span className="icon fas fa-envelope fa-lg"></span>

                    <input
                      type="text"
                      autoComplete="off"
                      name="email"
                      className="form-control"
                      placeholder="E-mail"
                      title="E-mail"
                      onChange={props.onChange2}
                      value={props.personalDetails.email._value}
                    />
                    {/* <span>E-mail</span> */}
                  </div>{" "}
                  {(props.submitPersonalDetails ||
                    props.personalDetails.email.touched) &&
                  !props.personalDetails.email._value ? (
                    <span className="text-danger">
                      {props.personalDetails.email.errorMsg}
                    </span>
                  ) : (
                    <span className="text-danger">
                      {props.personalDetails.email.errorMessage}
                    </span>
                  )}
                </div>
              </div>
              <br />
              <p>
                By Clicking on "Proceed", I declare that I am a resident of UAE
                and holding a valid Visa and agree to the website Privacy Policy
                and Terms of Use.
              </p>
            </div>
          </Sliders>

          <div>
            <Modal
              fade={false}
              size="sm"
              centered
              isOpen={props.smShow}
              toggle={() => props.setSmShow()}
            >
              <ModalBody>
                <div>
                  {" "}
                  <Label>
                    {props.responseMsg !== ""
                      ? props.responseMsg
                      : "Something Went Wrong"}
                  </Label>
                </div>
              </ModalBody>{" "}
              <ModalFooter>
                <Button color="secondary" onClick={() => props.setSmShow()}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

function Step3(props) {
  if (props.currentStep !== 3) {
    return null;
  }
  return (
    <React.Fragment>
      {props.loader && <div className="loading" />}
      {!props.loader && (
        <div>
          <Modal
            fade={false}
            size="sm"
            centered
            isOpen={props.smShow}
            toggle={() => props.setSmShow()}
          >
            <ModalBody>
              <div>
                {" "}
                <Label>
                  {props.responseMsg !== ""
                    ? props.responseMsg
                    : "Something Went Wrong"}
                </Label>
              </div>
            </ModalBody>{" "}
            <ModalFooter>
              <Button color="secondary" onClick={() => props.setSmShow()}>
                Ok
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </React.Fragment>
  );
}

export default App;
